import { GET_BILLING, UPDATE_BILLING, ADD_PAYMENT_METHOD } from "@actions/billing/"
import { get, post } from "@src/helpers/fetch"
import { GLOBALS } from "@src/config"

export const getBilling = () => dispatch => dispatch(get(GET_BILLING, "billing/cards"))

export const updateBilling = payload => dispatch =>
  dispatch({
    type: UPDATE_BILLING,
    payload
  })

export const addCard = card => (dispatch, getState) => {
  dispatch(updateBilling({ new_payment_method_status: "PENDING" }))
  if (!card.name) {
    dispatch(
      updateBilling({
        new_payment_method_error_message: "Name is required",
        new_payment_method_status: "READY"
      })
    )
  } else {
    window.Stripe.setPublishableKey(GLOBALS.STRIPE_KEY)
    window.Stripe.card.createToken(card, (status, response) => {
      if (response.error) {
        dispatch(
          updateBilling({
            new_payment_method_error_message: response.error.message,
            new_payment_method_status: "READY"
          })
        )
      } else {
        dispatch(post(ADD_PAYMENT_METHOD, "billing/cards", { token: response.id }))
      }
    })
  }
}
