import React from "react"
import PropTypes from "prop-types"
import "styled-components/macro"

export const ModalMaxHeightHelper = ({ children }) => (
  <section
    className='border-bottom border-gray-5'
    css={`
      max-height: 300px;
      overflow: auto;
      padding: 0 1em 2em;
      margin: 1em -1em 0;
    `}>
    {children}
  </section>
)

ModalMaxHeightHelper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]).isRequired
}

export default ModalMaxHeightHelper
