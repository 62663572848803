import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import DayPicker from "react-day-picker/DayPickerInput"
import dayjs from "dayjs"
import { updateCampaignFormModel } from "@api/campaign_form/"
import getMinimumDay from "../../../functions/minimum_day"
import getMaximumDay from "../../../functions/maximum_day"

const CampaignReviewForm = ({ model, updateCampaignFormModel, review }) => (
  <form className='flex items-start py2'>
    <div className='col-6 pr2'>
      <label htmlFor='campaign-name'>Campaign Title</label>
      <input
        id='campaign-title'
        className={`cs-input block col-12 ${review.validation_errors.includes("name") ? "error" : ""}`}
        onChange={e => updateCampaignFormModel({ name: e.target.value })}
        value={model.name}/>
      {review.validation_errors.includes("name") && <small className='red block'>This field is required.</small>}
    </div>
    {["starts_at", "ends_at"].map((type, index) => (
      <div
        key={type}
        css={`
          .DayPickerInput {
            width: 100%;
          }
        `}
        className={`col-6 ${index === 0 ? "pr2" : ""}`}>
        <label className='capitalize' htmlFor={type}>
          {type.replace("s_at", "")} date
        </label>
        <DayPicker
          key={type}
          value={model[type] ? dayjs(model[type]).toDate() : ""}
          inputProps={{
            className: `cs-input col-12 block ${review.validation_errors.includes(type) ? "error" : ""}`,
            id: type
          }}
          id={type}
          dayPickerProps={{
            disabledDays: {
              before: getMinimumDay(type, model.starts_at, model.ends_at),
              after: getMaximumDay(type, model.starts_at, model.ends_at)
            }
          }}
          placeholder=''
          formatDate={date => dayjs(date).format("MMM Do, YYYY")}
          onDayChange={date => updateCampaignFormModel({ [type]: dayjs(date).format("YYYY-MM-DD HH:mm:ss") })}/>
        {review.validation_errors.includes(type) && <small className='red block'>This field is required.</small>}
      </div>
    ))}
  </form>
)

const mapStateToProps = state => ({
  model: state.campaign_form.model,
  review: state.campaign_form.steps.review
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormModel: bindActionCreators(updateCampaignFormModel, dispatch)
})

CampaignReviewForm.propTypes = {
  updateCampaignFormModel: PropTypes.func.isRequired,
  model: PropTypes.shape({
    name: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired,
    starts_at: PropTypes.string.isRequired
  }),
  review: PropTypes.shape({
    validation_errors: PropTypes.array.isRequired
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignReviewForm)
