import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Helmet from "react-helmet"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import dayjsPluginUTC from "dayjs-plugin-utc"
import relativeTime from "dayjs/plugin/relativeTime"
import Pusher from "pusher-js"
import { hot, setConfig } from "react-hot-loader"
import { Switch, Route, withRouter } from "react-router-dom"
import RedirectWithStatus from "@src/components/redirect_with_status"
import { setPusherClient } from "@src/components/pusher"
import { updateUtilities } from "@api/utilities/"
import Campaigns from "@src/pages/campaigns/"
import Tracking from "@src/pages/tracking/"
import PageNotFound from "@src/pages/404/"
import AuthenticationWrapper from "@src/components/authentication_wrapper"
import Navigation from "@src/components/navigation"
import Messages from "@src/components/global_messages"
import ErrorBoundary from "@src/components/error"
import favicon from "@src/media/favicon.png"
import { GLOBALS } from '../config'
import "@src/css/index.scss"
import '@bitcine/cs-theme/dist/index.scss'
import 'react-day-picker/lib/style.css'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(dayjsPluginUTC)

// This line is needed to allow react-hot-loader to work with React Hooks
setConfig({ pureSFC: true })

const scripts = [
  { src: "https://js.stripe.com/v2/", props: { defer: true } },
  { src: "https://cdn.trackjs.com/releases/current/tracker.js", props: { defer: true } }
]

Pusher.logToConsole = GLOBALS.PUSHER_DEBUG
// if (process.env.NODE_ENV === "development") {
Pusher.log = msg => {
  const sizeof = require("object-sizeof")
  // prettier-ignore
  console.log("%c New Pusher log at " + dayjs().format("h:mm:ssa") + " with size of " + sizeof(msg) / 1000 + ' KB ', "background: #222; color: #bada55") // eslint-disable-line no-console
  // prettier-ignore
  console.log(msg)
}
// }
const pusherClient = new Pusher(GLOBALS.PUSHER_KEY, {
  encrypted: true,
  cluster: "mt1",
  authEndpoint: `${GLOBALS.API_URL}/pusher/auth`,
  authTransport: "jsonp"
})

setPusherClient(pusherClient)

class Root extends React.Component {
  componentDidMount() {
    this.props.updateUtilities("history", this.props.history)
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>CineSend Bookings</title>
          <meta httpEquiv='X-UA-Compatible' content='IE=edge'/>
          <meta charSet='utf-8'/>
          <meta httpEquiv='Content-Language' content='en'/>
          <meta name='viewport' content='width=device-width, initial-scale=1'/>
          <meta name='theme-color' content='white'/>
          {scripts.map(script => (
            <script key={script.src} src={script.src} type='text/javascript' {...script.props}/>
          ))}
          <link id='favicon' rel='shortcut icon' href={favicon} sizes='16x16 32x32' type='image/png'/>
        </Helmet>
        <AuthenticationWrapper>
          <Navigation/>
          <main className='p3'>
            <ErrorBoundary>
              <Switch>
                <RedirectWithStatus status={301} from='/index.html' to={this.props.match.url + "campaigns"}/>
                <RedirectWithStatus status={301} from='/' to={this.props.match.url + "campaigns"}/>
                <Route path='/campaigns' component={Campaigns}/>
                <Route path='/tracking' component={Tracking}/>
                <Route component={PageNotFound}/>
              </Switch>
            </ErrorBoundary>
          </main>
        </AuthenticationWrapper>
        <Messages/>
      </React.Fragment>
    )
  }
}

Root.propTypes = {
  updateUtilities: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object
}

const mapDispatchToProps = dispatch => ({
  updateUtilities: bindActionCreators(updateUtilities, dispatch)
})

export default hot(module)(
  withRouter(
    connect(
      null,
      mapDispatchToProps
    )(Root)
  )
)
