import {
  GET_CAMPAIGN_FORM_FULFILLED,
  PUT_CAMPAIGN_FORM_FULFILLED,
  POST_CAMPAIGN_FORM_FULFILLED,
  UPDATE_CAMPAIGN_FORM_MODEL,
  REMOVE_CONTENT_FROM_CAMPAIGN_FULFILLED,
  POST_CONTENT_TO_CAMPAIGN_FORM_FULFILLED,
  RESET_CAMPAIGN_FORM,
  ADD_CINEMAS_TO_NEW_CAMPAIGN_FULFILLED,
  REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FULFILLED,
  LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FULFILLED,
  UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_PENDING,
  UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FAILED
} from "@actions/campaign_form/"
import INITIAL_STATE from "../config"

export default function reducer(state = INITIAL_STATE.model, action = {}) {
  switch (action.type) {
    case RESET_CAMPAIGN_FORM:
      return INITIAL_STATE.model

    case UPDATE_CAMPAIGN_FORM_MODEL:
      return {
        ...state,
        ...action.payload
      }

    case UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_PENDING:
      return {
        ...state,
        excluded_screen_ids: state.excluded_screen_ids.includes(action.meta.__REDUX_updating_screen_id)
          ? state.excluded_screen_ids.filter(_id => _id !== action.meta.__REDUX_updating_screen_id)
          : [...state.excluded_screen_ids, action.meta.__REDUX_updating_screen_id]
      }

    case POST_CONTENT_TO_CAMPAIGN_FORM_FULFILLED:
    case GET_CAMPAIGN_FORM_FULFILLED:
    case PUT_CAMPAIGN_FORM_FULFILLED:
    case POST_CAMPAIGN_FORM_FULFILLED:
    case ADD_CINEMAS_TO_NEW_CAMPAIGN_FULFILLED:
    case REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FULFILLED:
    case LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FULFILLED:
    case REMOVE_CONTENT_FROM_CAMPAIGN_FULFILLED:
    case UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FAILED:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
