import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { removeContentFromCampaign, updateCampaignFormStep, resetNewCampaignFormStep } from "@api/campaign_form/"
import { Table, Button } from "@bitcine/cs-theme"
import { ReactComponent as EmptyIcon } from "@src/media/icons/empty.inline.svg"
import fadeIn from "@src/css/exports/fade_in"
import RowWrapper from "./components/row_wrapper"
import SelectCampaignFormContent from "./components/select_campaign_form_content/index"
import Header from "./components/header"

class Content extends React.Component {
  componentWillUnmount() {
    this.props.resetNewCampaignFormStep("content")
  }
  toggleChecked = data => {
    this.props.updateCampaignFormStep("content", {
      selected_content_ids: this.props.content.selected_content_ids.includes(data._id)
        ? this.props.content.selected_content_ids.filter(id => id !== data._id)
        : [...this.props.content.selected_content_ids, data._id]
    })
  }
  toggleHeaderChecked = () => {
    this.props.updateCampaignFormStep("content", {
      selected_content_ids: this.props.content.selected_content_ids.length ? [] : this.filter().map(({ _id }) => _id)
    })
  }
  filter = () => {
    return this.props.model.contents.filter(dcp => {
      const name = dcp.name || ""
      return name
        .trim()
        .toLowerCase()
        .includes(this.props.content.search_term.trim().toLowerCase())
    })
  }
  render() {
    const { content } = this.props
    const list = this.filter()
    return (
      <div
        css={`
          ${fadeIn()}
        `}
        className='bg-white box-shadow rounded p3'>
        <Header/>
        <Table
          className='my3'
          widths={["auto", "300", "100"]}
          header={{
            columns: [{ text: "File Name" }, { text: "Project Title" }, { text: "" }],
            checkbox: {
              checked: content.selected_content_ids.length > 0,
              intermediate: content.selected_content_ids.length !== list.length,
              onChange: value => this.toggleHeaderChecked(),
              disabled: !list.length
            }
          }}
          body={{
            data: list,
            empty: {
              icon: <EmptyIcon/>,
              title: content.search_term ? "No content found" : "No content selected",
              text: content.search_term ? "Try your search again" : 'Click "Add content" above to get started'
            },
            row: {
              customRender: (children, data) => (
                <RowWrapper removing={content.removing_content_ids.includes(data._id)}>{children}</RowWrapper>
              ),
              checkbox: {
                checked: data => content.selected_content_ids.includes(data._id),
                onChange: data => this.toggleChecked(data)
              },
              render: [
                data => data.name,
                data => (data.project ? data.project.title : "N/A"),
                data => (
                  <Button
                    onClick={() => this.props.removeContentFromCampaign(data._id)}
                    disabled={content.removing_content_ids.includes(data._id)}
                    className='link underline'>
                    <span className='regular caption'>Remove</span>
                  </Button>
                )
              ]
            }
          }}/>
        <SelectCampaignFormContent/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  content: state.campaign_form.steps.content,
  model: state.campaign_form.model
})

const mapDispatchToProps = dispatch => ({
  removeContentFromCampaign: bindActionCreators(removeContentFromCampaign, dispatch),
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch),
  resetNewCampaignFormStep: bindActionCreators(resetNewCampaignFormStep, dispatch)
})

Content.propTypes = {
  removeContentFromCampaign: PropTypes.func.isRequired,
  updateCampaignFormStep: PropTypes.func.isRequired,
  resetNewCampaignFormStep: PropTypes.func.isRequired,
  content: PropTypes.shape({
    search_term: PropTypes.string.isRequired,
    removing_content_ids: PropTypes.array.isRequired,
    selected_content_ids: PropTypes.array.isRequired
  }).isRequired,
  model: PropTypes.shape({
    contents: PropTypes.array.isRequired
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content)
