import { ADD_GLOBAL_MESSAGE, REMOVE_GLOBAL_MESSAGE } from "@actions/global_messages/"

/**
 * Adds a message to the global snackbar UI
 *
 * Based on @bitcine/cs-theme <Snackbar/>
 * For more info see: https://bitcine.github.io/@bitcine/cs-theme/
 *
 * @param {String/Node} [message]
 * A message or React element to render
 * Examples: 'Files Deleted!' or <div>Files Deleted</div>
 * String should be used if possible for performance reasons
 * @param {String} [type]
 * Either 'info', 'warning', 'error' or 'success'
 * Defauls to 'info'
 * @param {String/Null} [icon]
 * A material-icon https://material.io/tools/icons
 * If left blank, the icon will be rendered based on 'type' param
 * @param {Int/Null} [timeout]
 * The time to leave the snackbar in the UI.
 * Defaults to 3000 and 'null' will keep it always open (use with caution)
 * @param {Boolean} [showCloseButton]
 * Shows the close button in the UI
 *
 * @returns {String} [_id] The unique identifer for the message
 */

export const addGlobalMessage = (message, type = "info", icon, timeout = 3000, showCloseButton = false) => dispatch => {
  const _id = Math.random()
    .toString(36)
    .substring(7)
  dispatch({
    type: ADD_GLOBAL_MESSAGE,
    payload: {
      _id,
      message,
      icon,
      type,
      timeout,
      showCloseButton,
      onClose: () => dispatch(removeGlobalMessage(_id))
    }
  })
  return _id
}

/**
 * Removes a message from the global snackbar UI
 *
 * @param {String} [_id] The unique identifer of the
 * snackbar item that is created when its added to state
 *
 * @returns {Function} A redux dispatch
 */

export const removeGlobalMessage = _id => dispatch => dispatch({ type: REMOVE_GLOBAL_MESSAGE, _id })
