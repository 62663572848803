import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { getBilling, updateBilling, addCard } from "@api/billing/"
import { Status, Button } from "@bitcine/cs-theme"
import Card from "@src/components/_temp_payment/card"
import { ReactComponent as NoCards } from "@src/components/_temp_payment/images/no_cards.inline.svg"
import AddPaymentMethod from "@src/components/_temp_payment/add_payment_method/"
import PostPay from "@src/components/_temp_payment/postpay"

class Billing extends React.Component {
  componentDidMount() {
    this.props.getBilling()
  }
  render() {
    const { billing, canPostPay, updateBilling, addCard, errors } = this.props
    return (
      <div className='pt3 border-top border-gray-5'>
        <div className='flex items-center justify-between pb2'>
          <strong
            css={`
              font-size: 1.3em;
            `}>
            Payment Method
          </strong>
          <Button onClick={() => updateBilling({ show_new_payment_method: true })} className='white small'>
            Add Payment Method
          </Button>
        </div>
        {errors.includes("active_card_id") && !billing.active_card_id && (
          <div className='bg-red flex items-center rounded my1 px1 py2 white bold'>
            <span
              css={`
                font-size: 24px;
              `}
              className='material-icons mr1'>
              error
            </span>
            Credit card is required
          </div>
        )}
        <Status
          height={150}
          pending={billing.status === "PENDING"}
          error={billing.status === "ERROR"}
          empty={!billing.credit_cards.length && !canPostPay}
          emptyMessage={{
            icon: <NoCards/>,
            title: "No Payment Methods",
            text: ""
          }}>
          {billing.credit_cards.map((card, index) => (
            <Card
              key={card._id}
              card={card}
              selected={billing.active_card_id === card._id}
              onSelect={() =>
                updateBilling({
                  active_card_id: billing.active_card_id === card._id ? null : card._id,
                  use_post_pay: false
                })
              }/>
          ))}
          {canPostPay && (
            <PostPay
              selected={billing.use_post_pay}
              onSelect={() => updateBilling({ active_card_id: null, use_post_pay: !billing.use_post_pay })}/>
          )}
        </Status>
        {billing.show_new_payment_method && (
          <AddPaymentMethod
            onClose={() => updateBilling({ show_new_payment_method: false })}
            addCard={card => addCard(card)}
            status={billing.new_payment_method_status}
            errorMessage={billing.new_payment_method_error_message}
            modes={["credit-card"]}/>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  billing: state.billing,
  errors: state.campaign_form.steps.review.validation_errors,
  canPostPay: state.auth.organization.can_post_pay
})

const mapDispatchToProps = dispatch => ({
  getBilling: bindActionCreators(getBilling, dispatch),
  updateBilling: bindActionCreators(updateBilling, dispatch),
  addCard: bindActionCreators(addCard, dispatch)
})

Billing.propTypes = {
  billing: PropTypes.shape({
    credit_cards: PropTypes.array.isRequired,
    active_card_id: PropTypes.string,
    show_new_payment_method: PropTypes.bool.isRequired,
    new_payment_method_error_message: PropTypes.string,
    new_payment_method_status: PropTypes.string.isRequired
  }).isRequired,
  canPostPay: PropTypes.bool.isRequired,
  getBilling: PropTypes.func.isRequired,
  updateBilling: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing)
