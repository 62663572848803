export const MODES = [
  {
    key: "credit-card",
    label: "Credit Card",
    icon: "credit_card",
    show_allowed_credit_cards: true
  },
  {
    key: "gift-card",
    label: "Gift Card",
    icon: "card_giftcard",
    show_allowed_credit_cards: false
  }
]
