import {
  GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_PENDING,
  GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_FAILED,
  GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_FULFILLED,
  POST_CONTENT_TO_CAMPAIGN_FORM_PENDING,
  POST_CONTENT_TO_CAMPAIGN_FORM_FAILED,
  POST_CONTENT_TO_CAMPAIGN_FORM_FULFILLED,
  UPDATE_CAMPAIGN_FORM_STEP,
  REMOVE_CONTENT_FROM_CAMPAIGN_PENDING,
  REMOVE_CONTENT_FROM_CAMPAIGN_FAILED,
  REMOVE_CONTENT_FROM_CAMPAIGN_FULFILLED,
  RESET_CAMPAIGN_FORM,
  POST_NEW_CAMPAIGN_TEMPLATE_PENDING,
  POST_NEW_CAMPAIGN_TEMPLATE_FAILED,
  POST_NEW_CAMPAIGN_TEMPLATE_FULFILLED,
  RESET_NEW_CAMPAIGN_NEW_TEMPLATE,
  RESET_NEW_CAMPAIGN_LOAD_TEMPLATE,
  GET_NEW_CAMPAIGN_TEMPLATES_PENDING,
  GET_NEW_CAMPAIGN_TEMPLATES_FAILED,
  GET_NEW_CAMPAIGN_TEMPLATES_FULFILLED,
  LOAD_TEMPLATES_TO_NEW_CAMPAIGN_PENDING,
  LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FAILED,
  LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FULFILLED,
  GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_PENDING,
  GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_FAILED,
  GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_FULFILLED,
  ADD_CINEMAS_TO_NEW_CAMPAIGN_PENDING,
  ADD_CINEMAS_TO_NEW_CAMPAIGN_FAILED,
  ADD_CINEMAS_TO_NEW_CAMPAIGN_FULFILLED,
  REMOVE_CINEMA_FROM_NEW_CAMPAIGN_PENDING,
  REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FULFILLED,
  REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FAILED,
  UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_PENDING,
  UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FAILED,
  UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FULFILLED,
  RESET_NEW_CAMPAIGN_STEP,
  UPDATE_CAMPAIGN_FORM_CINEMA_SEARCH
} from "@actions/campaign_form/"
import INITIAL_STATE from "../config"

export default function reducer(state = INITIAL_STATE.steps, action = {}) {
  switch (action.type) {
    case RESET_CAMPAIGN_FORM:
      return INITIAL_STATE.steps

    case RESET_NEW_CAMPAIGN_STEP:
      return {
        ...INITIAL_STATE.steps,
        [action.step]: INITIAL_STATE.steps[action.step]
      }

    case UPDATE_CAMPAIGN_FORM_STEP:
      return {
        ...state,
        [action.step]: {
          ...state[action.step],
          ...action.payload
        }
      }

    case GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_PENDING:
      return {
        ...state,
        content: {
          ...state.content,
          available_list: {
            ...state.content.available_list,
            status: "PENDING"
          }
        }
      }
    case GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_FAILED:
      return {
        ...state,
        content: {
          ...state.content,
          available_list: {
            ...state.content.available_list,
            status: "ERROR"
          }
        }
      }

    case GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_FULFILLED:
      return {
        ...state,
        content: {
          ...state.content,
          available_list: {
            ...state.content.available_list,
            status: "READY",
            projects: action.payload
          }
        }
      }

    case POST_CONTENT_TO_CAMPAIGN_FORM_PENDING:
      return {
        ...state,
        content: {
          ...state.content,
          available_list: {
            ...state.content.available_list,
            status: "PENDING"
          }
        }
      }

    case POST_CONTENT_TO_CAMPAIGN_FORM_FAILED:
      return {
        ...state,
        content: {
          ...state.content,
          available_list: {
            ...state.content.available_list,
            status: "ERROR"
          }
        }
      }

    case POST_CONTENT_TO_CAMPAIGN_FORM_FULFILLED:
      return {
        ...state,
        content: {
          ...state.content,
          available_list: INITIAL_STATE.steps.content.available_list
        }
      }

    case REMOVE_CONTENT_FROM_CAMPAIGN_PENDING:
      return {
        ...state,
        content: {
          ...state.content,
          removing_content_ids: action.meta.__REDUX_deleting_content_ids
        }
      }

    case REMOVE_CONTENT_FROM_CAMPAIGN_FAILED:
    case REMOVE_CONTENT_FROM_CAMPAIGN_FULFILLED:
      return {
        ...state,
        content: {
          ...state.content,
          removing_content_ids: [],
          selected_content_ids: []
        }
      }

    case POST_NEW_CAMPAIGN_TEMPLATE_PENDING:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          new_template: {
            ...state.cinemas.new_template,
            status: "PENDING"
          }
        }
      }

    case POST_NEW_CAMPAIGN_TEMPLATE_FAILED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          new_template: {
            ...state.cinemas.new_template,
            status: "ERROR"
          }
        }
      }

    case POST_NEW_CAMPAIGN_TEMPLATE_FULFILLED:
    case RESET_NEW_CAMPAIGN_NEW_TEMPLATE:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          new_template: INITIAL_STATE.steps.cinemas.new_template
        }
      }

    case RESET_NEW_CAMPAIGN_LOAD_TEMPLATE:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          saved_templates: INITIAL_STATE.steps.cinemas.saved_templates
        }
      }

    case GET_NEW_CAMPAIGN_TEMPLATES_PENDING:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          saved_templates: {
            ...state.cinemas.saved_templates,
            status: "PENDING"
          }
        }
      }

    case GET_NEW_CAMPAIGN_TEMPLATES_FAILED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          saved_templates: {
            ...state.cinemas.saved_templates,
            status: "ERROR"
          }
        }
      }

    case GET_NEW_CAMPAIGN_TEMPLATES_FULFILLED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          saved_templates: {
            ...state.cinemas.saved_templates,
            status: "READY",
            list: action.payload
          }
        }
      }

    case LOAD_TEMPLATES_TO_NEW_CAMPAIGN_PENDING:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          saved_templates: {
            ...state.cinemas.saved_templates,
            status: "PENDING"
          }
        }
      }

    case LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FAILED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          saved_templates: {
            ...state.cinemas.saved_templates,
            status: "ERROR"
          }
        }
      }

    case LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FULFILLED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          saved_templates: INITIAL_STATE.steps.cinemas.saved_templates
        }
      }

    case ADD_CINEMAS_TO_NEW_CAMPAIGN_PENDING:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          available_list: {
            ...state.cinemas.available_list,
            status: "PENDING"
          }
        }
      }
    case ADD_CINEMAS_TO_NEW_CAMPAIGN_FAILED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          available_list: {
            ...state.cinemas.available_list,
            status: "ERROR"
          }
        }
      }
    case ADD_CINEMAS_TO_NEW_CAMPAIGN_FULFILLED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          available_list: INITIAL_STATE.steps.cinemas.available_list
        }
      }

    case REMOVE_CINEMA_FROM_NEW_CAMPAIGN_PENDING:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          removing_cinema_ids: action.meta.__REDUX_deleting_cinema_ids
        }
      }

    case REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FULFILLED:
    case REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FAILED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          removing_cinema_ids: [],
          selected_cinema_ids: []
        }
      }

    case UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_PENDING:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          updating_screen_ids: [...state.cinemas.updating_screen_ids, action.meta.__REDUX_updating_screen_id]
        }
      }

    case UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FULFILLED:
    case UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FAILED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          updating_screen_ids: state.cinemas.updating_screen_ids.filter(
            id => id !== action.meta.__REDUX_updating_screen_id
          )
        }
      }

    case UPDATE_CAMPAIGN_FORM_CINEMA_SEARCH:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          available_list: {
            ...state.cinemas.available_list,
            search_term: action.value,
            search_status: action.value.length > 2 ? "PENDING" : "READY"
          }
        }
      }

    case GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_PENDING:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          available_list: {
            ...state.cinemas.available_list,
            search_status: "PENDING"
          }
        }
      }

    case GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_FAILED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          available_list: {
            ...state.cinemas.available_list,
            search_status: "ERROR"
          }
        }
      }

    case GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_FULFILLED:
      return {
        ...state,
        cinemas: {
          ...state.cinemas,
          available_list: {
            ...state.cinemas.available_list,
            search_status: "READY",
            list: action.payload
          }
        }
      }

    default:
      return state
  }
}
