import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { getCampaignPrice } from "@api/campaign_form/"
import { Status } from "@bitcine/cs-theme"
import centsToDollars from "@src/helpers/cents_to_dollars"

const divider = `
	background-image: linear-gradient(to right, #acacac 33%, rgba(255,255,255,0) 0%);
	background-position: bottom;
	background-size: 5px 2px;
	background-repeat: repeat-x;
	height: 1px;
`
class Total extends React.Component {
  componentDidMount() {
    this.props.getCampaignPrice()
  }
  render() {
    const { price } = this.props
    return (
      <Status height={140} pending={this.props.price.status === "PENDING"} error={this.props.price.status === "ERROR"}>
        <div className='flex items-center justify-between mb2'>
          <span>Subtotal:</span>
          <span className='flex-auto mx1 pt1' css={divider}/>
          <span>
            {price.currency_symbol}
            {centsToDollars(price.sub_total)} {price.currency_code}
          </span>
        </div>
        <div className='flex items-center justify-between mb2'>
          <span>Taxes:</span>
          <span className='flex-auto mx1 pt1' css={divider}/>
          <span>
            {price.currency_symbol}
            {centsToDollars(price.tax_total)} {price.currency_code}
          </span>
        </div>
        <div
          css={`
            font-size: 1.3em;
          `}
          className='flex items-center justify-between mb3'>
          <strong>Order Total:</strong>
          <span
            className='flex-auto mx1'
            css={`
              ${divider};
              padding-top: 0.75em;
            `}/>
          <strong>
            {price.currency_symbol}
            {centsToDollars(price.grand_total)} {price.currency_code}
          </strong>
        </div>
      </Status>
    )
  }
}

const mapStateToProps = state => ({
  price: state.campaign_form.price
})

const mapDispatchToProps = dispatch => ({
  getCampaignPrice: bindActionCreators(getCampaignPrice, dispatch)
})

Total.propTypes = {
  price: PropTypes.shape({
    status: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    taxes: PropTypes.string.isRequired
  }).isRequired,
  getCampaignPrice: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Total)
