import {
  GET_CAMPAIGN_PENDING,
  GET_CAMPAIGN_FAILED,
  GET_CAMPAIGN_FULFILLED,
  PUSHER_CAMPAIGN_CINEMA_UPDATED_EVENT
} from "@actions/campaign/index"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_CAMPAIGN_PENDING:
      return INITIAL_STATE

    case GET_CAMPAIGN_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_CAMPAIGN_FULFILLED:
      return {
        status: "READY",
        ...action.payload
      }

    case PUSHER_CAMPAIGN_CINEMA_UPDATED_EVENT:
      return {
        ...state,
        cinema_meta: {
          ...state.cinema_meta,
          [action.payload.cinema_id]: action.payload.value
        }
      }

    default:
      return state
  }
}
