const INITIAL_STATE = {
  utils: {
    status: "READY",
    disable_navigation: false
  },
  price: {
    status: "PENDING",
    total: "",
    taxes: ""
  },
  model: {
    _id: "",
    name: "",
    starts_at: "",
    ends_at: "",
    created_at: "",
    contents: [],
    cinemas: [],
    cinema_overrider_dates_map: {},
    template_cinema_map: {}
  },
  steps: {
    details: {
      validation_errors: []
    },
    content: {
      search_term: "",
      sort_by: "",
      sort_direction: "",
      show_add_content_helper_tooltip: false,
      removing_content_ids: [],
      selected_content_ids: [],
      available_list: {
        projects: [],
        status: "READY",
        search_term: "",
        show: false,
        opened_project_id: "",
        selected_content_ids: []
      }
    },
    cinemas: {
      search_term: "",
      sort_by: "",
      sort_direction: "",
      show_add_cinemas_helper_tooltip: false,
      removing_cinema_ids: [],
      selected_cinema_ids: [],
      opened_cinema_ids: [],
      updating_screen_ids: [],
      available_list: {
        search_term: "",
        take: "25",
        search_status: "READY",
        status: "READY",
        show: false,
        list: [],
        selected_cinema_ids: []
      },
      saved_templates: {
        show: false,
        search_term: "",
        list: [],
        selected_template_ids: []
      },
      new_template: {
        name: "",
        show: false,
        status: "READY"
      }
    },
    review: {
      validation_errors: []
    }
  }
}

export default INITIAL_STATE
