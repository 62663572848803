import React from "react"
import Total from "./total"
import Billing from "./billing"

const Payment = () => (
  <div className='bg-white rounded box-shadow p3'>
    <Total/>
    <Billing/>
  </div>
)

export default Payment
