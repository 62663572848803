import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import campaigns from "./campaigns/index"
import campaignForm from "./campaign_form/index"
import messages from "./global_messages/index"
import auth from "./auth/index"
import utilities from "./utilities/index"
import billing from "./billing/index"
import campaign from "./campaign/index"
import tracking from "./tracking/index"

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  campaigns: campaigns,
  campaign: campaign,
  campaign_form: campaignForm,
  global_messages: messages,
  auth: auth,
  utilities: utilities,
  billing: billing,
  tracking: tracking
})

export default createRootReducer
