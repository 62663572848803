import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { Checkbox } from "@bitcine/cs-theme"
import { updateNewCampaignCinemaScreens } from "@api/campaign_form/index"

const exists = string => string && typeof string === "string" && string !== null && string.length > 0

const renderScreenLabelToString = screen => (
  <div className='nowrap'>
    Screen {screen.screen_id}
    <span className='regular muted'>
      &nbsp;-&nbsp;
      {exists(screen.resolution) && `${screen.resolution}, `}
      {exists(screen.device_3d) && `${screen.device_3d}, `}
      {exists(screen.aspect_ratio) && `${screen.aspect_ratio}, `}
      {exists(screen.audio_formats) && `${screen.audio_formats}`}
      {Array.isArray(screen.audio_formats) &&
        screen.audio_formats.length > 0 &&
        `${screen.audio_formats
          .filter(format => exists(format))
          .map(
            (format, index) =>
              `${format}${index < screen.audio_formats.filter(format => exists(format)).length - 1 ? ", " : ""}`
          )}`}
    </span>
  </div>
)

const Screens = ({ cinema, model, updateNewCampaignCinemaScreens }) => (
  <div className='col-4'>
    <strong>Selected screens at cinema</strong>
    <div className='flex items-start flex-wrap mt1'>
      {cinema.screens.map(screen => (
        <div
          key={screen._id}
          css={`
            > label {
              padding: 0.5em;
              width: 100%;
              justify-content: start;
              :focus,
              :hover {
                box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.13);
              }
            }
          `}
          className='border rounded border-gray-5 mr1 mt1 flex items-center p1'>
          <Checkbox
            onChange={() => updateNewCampaignCinemaScreens(screen._id)}
            label={renderScreenLabelToString(screen)}
            checked={!model.excluded_screen_ids || !model.excluded_screen_ids.includes(screen._id)}/>
        </div>
      ))}
    </div>
  </div>
)

const mapStateToProps = state => ({
  model: state.campaign_form.model
})

const mapDispatchToProps = dispatch => ({
  updateNewCampaignCinemaScreens: bindActionCreators(updateNewCampaignCinemaScreens, dispatch)
})

Screens.propTypes = {
  screens: PropTypes.array,
  model: PropTypes.shape({
    excluded_screen_ids: PropTypes.array
  }).isRequired,
  cinema: PropTypes.shape({
    screens: PropTypes.array.isRequired
  }).isRequired,
  updateNewCampaignCinemaScreens: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screens)
