import { keyframes } from "react-emotion"

const animation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const fadeIn = (duration = 0.3, delay = 0.1) => `
  opacity: 0;
  animation: ${animation} ${duration}s linear forwards ${delay}s
`

export default fadeIn
