import { UPDATE_UTILITIES } from "@actions/utilities"

const initialState = {
  history: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_UTILITIES:
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state
  }
}
