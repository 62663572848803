import { combineReducers } from "redux"
import utils from "./utils/index"
import steps from "./steps/index"
import model from "./model/index"
import price from "./price/index"

const rootReducer = combineReducers({
  utils,
  steps,
  model,
  price
})

export default rootReducer
