import dayjs from "dayjs"

const isCardExpired = card => {
  return (
    card.exp_year < dayjs().format("YYYY") ||
    (card.exp_year === dayjs().format("YYYY") && card.exp_month < dayjs().format("M"))
  )
}

export default isCardExpired
