import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { updateCampaigns } from "@api/campaigns/"
import { ButtonDropdown } from "@bitcine/cs-theme"

export const CampaignListTableHeader = ({ updateCampaigns, campaigns }) => (
  <div className='border-bottom border-gray-5 mb2 px3'>
    <ButtonDropdown
      button={{
        text: <div>Campaign created date &#x2193;</div>,
        className: "link muted",
        style: { fontSize: "10px", fontWeight: "normal", height: "24px" }
      }}
      dropdown={{
        content: [
          {
            text: "Newest to Oldest",
            icon: campaigns.sort_direction === "DESC" ? "check" : "",
            direction: "DESC"
          },
          {
            text: "Oldest to Newest",
            icon: campaigns.sort_direction === "ASC" ? "check" : "",
            direction: "ASC"
          }
        ].map(btn => ({
          text: btn.text,
          icon: (
            <div
              css={`
                width: 18px;
              `}
              className='red'>
              {btn.icon}
            </div>
          ),
          onClick: () => updateCampaigns({ sort_direction: btn.direction })
        }))
      }}/>
  </div>
)

const mapStateToProps = state => ({
  campaigns: state.campaigns
})

const mapDispatchToProps = dispatch => ({
  updateCampaigns: bindActionCreators(updateCampaigns, dispatch)
})

CampaignListTableHeader.propTypes = {
  updateCampaigns: PropTypes.func.isRequired,
  campaigns: PropTypes.shape({
    search_term: PropTypes.string.isRequired
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignListTableHeader)
