import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import "styled-components/macro"

const RowWrapper = ({ muted, children }) => {
  return (
    <div
      className='rounded pointer'
      css={`
        opacity: ${muted ? 0.1 : 1};
        transition: opacity 0.3s linear;
        box-shadow: inset 0 -1px 0 0 #ebeced, 0 2px 7px 0 rgba(224, 227, 228, 0.62);
        .__body_row {
          box-shadow: none !important;
        }
      `}>
      {children}
    </div>
  )
}

const mapStateToProps = ({ campaign_form }, { cinema }) => ({
  model: campaign_form.model,
  muted:
    campaign_form.steps.cinemas.removing_cinema_ids.includes(cinema._id) ||
    (campaign_form.steps.cinemas.opened_cinema_ids.length > 0 &&
      !campaign_form.steps.cinemas.opened_cinema_ids.includes(cinema._id))
})

RowWrapper.propTypes = {
  muted: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array]).isRequired
}

export default connect(
  mapStateToProps,
  null
)(RowWrapper)
