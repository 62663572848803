import React from "react"
import PropTypes from "prop-types"
import "styled-components/macro"
import { Modal, Button, Status } from "@bitcine/cs-theme"
import CreditCardInput from "react-credit-card-input"
import { MODES } from "./config"
import { ReactComponent as Amex } from "./images/amex.inline.svg"
import { ReactComponent as Mastercard } from "./images/mastercard.inline.svg"
import { ReactComponent as Visa } from "./images/visa.inline.svg"

class AddPaymentMethod extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: "credit-card",
      name: "",
      number: "",
      expiry: "",
      cvc: ""
    }
  }
  addCard = e => {
    e.preventDefault()
    e.stopPropagation()
    const { name, number, expiry, cvc } = this.state
    this.props.addCard({
      name,
      number,
      cvc,
      exp_month: expiry.split("/")[0].trim(),
      exp_year: expiry.split("/")[1] ? expiry.split("/")[1].trim() : ""
    })
  }
  render() {
    return (
      <Modal open={true} title='Add Payment Method' onClose={this.props.onClose}>
        <Status pending={this.props.status === "PENDING"} error={this.props.status === "ERROR"}>
          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              {MODES.filter(mode => this.props.modes.includes(mode.key)).map(mode => (
                <Button
                  onClick={() => this.setState({ mode: mode.key })}
                  css={`
                    height: 100px;
                    width: 115px;
                    box-shadow: ${mode.key === this.state.mode
                  ? "inset 0 1px 3px 0 rgba(0,0,0,.5)"
                  : "none"} !important;
                    border: 1px solid ${mode.key === this.state.mode ? "" : "#e0e3e4"} !important;
                  `}
                  className={`flex items-center justify-center mr2 link border border-gray-5 ${
                    this.state.mode !== mode.key ? "muted" : ""
                  }`}
                  key={mode.key}>
                  <div>
                    <span className='material-icons'>{mode.icon}</span>
                    <div className='regular'>{mode.label}</div>
                  </div>
                </Button>
              ))}
            </div>
            {this.state.mode === "credit-card" && (
              <div>
                Accepted Cards
                <div className='flex items-center justify-end'>
                  <Visa/>
                  <Mastercard className='ml1'/>
                  <Amex className='ml1'/>
                </div>
              </div>
            )}
          </div>
          <form className='py3'>
            <label htmlFor='name'>Cardholder Full Name</label>
            <input
              value={this.state.name}
              ref={ref => (this.nameInput = ref)}
              id='name'
              placeholder='Jim Smith'
              onChange={e => this.setState({ name: e.target.value })}
              className='block col-12 mb2 cs-input'/>
            <label htmlFor='card-number'>Credit Card</label>
            <CreditCardInput
              cardNumberInputProps={{
                value: this.state.number,
                style: { flex: "auto", minWidth: "190px" },
                onChange: e => this.setState({ number: e.target.value })
              }}
              cardExpiryInputProps={{
                value: this.state.expiry,
                style: { minWidth: "120px" },
                onChange: e => this.setState({ expiry: e.target.value })
              }}
              cardCVCInputProps={{
                value: this.state.cvc,
                style: { minWidth: "100px" },
                onChange: e => this.setState({ cvc: e.target.value })
              }}
              containerClassName='col-12 block mb2 rounded'
              fieldStyle={{
                border: "1px solid #C7CDCF",
                height: "40px",
                display: "flex"
              }}
              fieldClassName='col-12 block'/>
            {this.props.errorMessage && <div className='red mt1'>{this.props.errorMessage}</div>}
            <div className='right-align mt2'>
              <Button onClick={this.addCard}>Add Card</Button>
            </div>
          </form>
        </Status>
      </Modal>
    )
  }
}

AddPaymentMethod.defaultProps = {
  modes: ["credit-card", "gift-card"]
}

AddPaymentMethod.propTypes = {
  onClose: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  status: PropTypes.oneOf(["READY", "PENDING", "ERROR"]).isRequired,
  modes: PropTypes.array
}

export default AddPaymentMethod
