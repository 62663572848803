import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { keyframes } from "react-emotion"
import "styled-components/macro"
import { Status } from "@bitcine/cs-theme"
import { updateCampaigns, getCampaigns } from "@api/campaigns/"
import { ReactComponent as EmptyIcon } from "@src/media/icons/empty.inline.svg"
import filterCampaigns from "@src/helpers/filter_campaigns"
import Campaign from "./components/campaign"
import CampaignListHeader from "./components/campaign_list_header"
import CampaignListTableHeader from "./components/campaign_list_table_header"

const up = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

export class CampaignList extends React.Component {
  componentDidMount() {
    this.props.getCampaigns()
  }
  render() {
    const campaigns = this.props.campaigns
    const list = filterCampaigns(campaigns.list, campaigns.search_term, campaigns.sort_by, campaigns.sort_direction)
    return (
      <section>
        <CampaignListHeader/>
        <Status
          pending={campaigns.status === "PENDING"}
          error={campaigns.status === "FAILED"}
          empty={campaigns.status === "READY" && list.length === 0}
          emptyMessage={{
            icon: <EmptyIcon/>,
            title: campaigns.search_term ? "No campaigns were found" : "You have no campaigns started yet!",
            text: campaigns.search_term ? "Try your search again" : 'Click "Create Campaign" above to get started'
          }}>
          <div
            css={`
              opacity: 0;
              animation: ${up} 0.3s linear 0.3s forwards;
            `}
            className='py4 max-width-4 mx-auto'>
            <CampaignListTableHeader/>
            {list.map(campaign => (
              <Campaign campaign={campaign} key={campaign._id}/>
            ))}
          </div>
        </Status>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  campaigns: state.campaigns
})

const mapDispatchToProps = dispatch => ({
  updateCampaigns: bindActionCreators(updateCampaigns, dispatch),
  getCampaigns: bindActionCreators(getCampaigns, dispatch)
})

CampaignList.propTypes = {
  updateCampaigns: PropTypes.func.isRequired,
  getCampaigns: PropTypes.func.isRequired,
  campaigns: PropTypes.shape({
    search_term: PropTypes.string.isRequired
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignList)
