import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Select from "react-select"
import Highlighter from "react-highlight-words"
import "styled-components/macro"
import { css } from "emotion"
import {
  updateCampaignFormStep,
  updateCampaignFormCinemaSearch,
  onCinemaSearch,
  addCinemasToNewCampaign
} from "@api/campaign_form/"
import { Modal, Status, Table, Button } from "@bitcine/cs-theme"
import { ReactComponent as EmptyIcon } from "@src/media/icons/empty.inline.svg"
import debounce from "lodash.debounce"
import ModalMaxHeightHelper from "@src/pages/campaigns/form/components/max_height_helper"
import centsToDollars from "@src/helpers/cents_to_dollars"

const highlightClassName = css`
  background: #fca2b1;
`

class SelectCinemas extends React.Component {
  toggleChecked = data => {
    this.props.updateCampaignFormStep("cinemas", {
      available_list: {
        ...this.props.cinemas,
        selected_cinema_ids: this.props.cinemas.selected_cinema_ids.includes(data._id)
          ? this.props.cinemas.selected_cinema_ids.filter(id => id !== data._id)
          : [...this.props.cinemas.selected_cinema_ids, data._id]
      }
    })
  }
  toggleHeaderChecked = () => {
    this.props.updateCampaignFormStep("cinemas", {
      available_list: {
        ...this.props.cinemas,
        selected_cinema_ids: this.props.cinemas.selected_cinema_ids.length
          ? []
          : this.props.cinemas.list.map(({ _id }) => _id)
      }
    })
  }
  onSearchKeyUp = debounce(e => {
    this.props.onCinemaSearch()
  }, 500)
  render() {
    const { cinemas, updateCampaignFormStep, updateCampaignFormCinemaSearch } = this.props
    return (
      <Modal
        title='Add cinemas to campaign'
        open={true}
        width={2}
        onClose={() => updateCampaignFormStep("cinemas", { available_list: { ...cinemas, show: false } })}>
        <Status pending={cinemas.status === "PENDING"} error={cinemas.status === "ERROR"}>
          <div className='flex items-center justify-end mb3'>
            <input
              css={`
                height: 38px;
              `}
              autoFocus
              placeholder='Search cinemas...'
              className='mr2 cs-input'
              value={cinemas.search_term}
              onKeyUp={this.onSearchKeyUp}
              onChange={e => updateCampaignFormCinemaSearch(e.target.value)}/>
            <Select
              options={["10", "25", "50", "100"].map(value => ({ value, label: `${value} results` }))}
              defaultValue={{ value: cinemas.take, label: `${cinemas.take} results` }}
              styles={{
                container: provided => ({
                  ...provided,
                  width: "125px"
                }),
                valueContainer: provided => ({
                  ...provided,
                  height: "32px"
                }),
                option: provided => ({
                  ...provided,
                  height: "32px"
                })
              }}
              isSearchable={false}
              onChange={({ value }) =>
                updateCampaignFormStep("cinemas", { available_list: { ...cinemas, take: value } })
              }/>
          </div>
          <Status pending={cinemas.search_status === "PENDING"} error={cinemas.search_status === "ERROR"}>
            <ModalMaxHeightHelper>
              <Table
                widths={["auto", "130"]}
                css={`
                  .__body > div {
                    height: ${!this.props.cinemas.list.length ? "220px" : ""};
                  }
                `}
                header={{
                  columns: [{ text: "Theater" }, { text: "Delivery Cost" }],
                  checkbox: {
                    checked: cinemas.selected_cinema_ids.length > 0,
                    intermediate: cinemas.selected_cinema_ids.length !== this.props.cinemas.list.length,
                    onChange: value => this.toggleHeaderChecked(),
                    disabled: !this.props.cinemas.list.length
                  }
                }}
                body={{
                  data: this.props.cinemas.list,
                  empty: {
                    icon: <EmptyIcon/>,
                    title: cinemas.search_term ? "No content found" : "No cinemas added",
                    text: cinemas.search_term ? "Try your search again" : "Try searching for a cinema to get started"
                  },
                  row: {
                    onClick: (e, data) => this.toggleChecked(data),
                    checkbox: {
                      checked: data => cinemas.selected_cinema_ids.includes(data._id),
                      onChange: data => this.toggleChecked(data)
                    },
                    render: [
                      data => (
                        <div>
                          <Highlighter
                            highlightClassName={highlightClassName}
                            autoEscape={true}
                            searchWords={cinemas.search_term.split(" ")}
                            textToHighlight={data.name}/>
                          <small className='block muted capitalize'>
                            <Highlighter
                              highlightClassName={highlightClassName}
                              autoEscape={true}
                              searchWords={cinemas.search_term.split(" ")}
                              textToHighlight={`
																${data.address ? `${data.address}, ` : ""}
														    ${data.city ? `${data.city}, ` : ""}
														    ${data.state ? `${data.state.toUpperCase()}, ` : ""}
														    ${data.country ? `${data.country}` : ""}
															`}/>
                          </small>
                        </div>
                      ),
                      data =>
                        `${data.shipping_cost_currency_symbol}${centsToDollars(data.shipping_cost)} ${
                          data.shipping_cost_currency_code
                        }`
                    ]
                  }
                }}/>
            </ModalMaxHeightHelper>
          </Status>

          <div className='right-align mt3'>
            <Button onClick={this.props.addCinemasToNewCampaign} disabled={!cinemas.selected_cinema_ids.length}>
              Add Cinema{cinemas.selected_cinema_ids.length > 0 ? "s" : ""}
            </Button>
          </div>
        </Status>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  cinemas: state.campaign_form.steps.cinemas.available_list
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch),
  onCinemaSearch: bindActionCreators(onCinemaSearch, dispatch),
  addCinemasToNewCampaign: bindActionCreators(addCinemasToNewCampaign, dispatch),
  updateCampaignFormCinemaSearch: bindActionCreators(updateCampaignFormCinemaSearch, dispatch)
})

SelectCinemas.propTypes = {
  updateCampaignFormStep: PropTypes.func.isRequired,
  addCinemasToNewCampaign: PropTypes.func.isRequired,
  updateCampaignFormCinemaSearch: PropTypes.func.isRequired,
  onCinemaSearch: PropTypes.func.isRequired,
  cinemas: PropTypes.shape({
    search_term: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    selected_cinema_ids: PropTypes.array.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCinemas)
