import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import "styled-components/macro"
import { updateCampaignFormStep } from "@api/campaign_form/"
import { connect } from "react-redux"
import { Checkbox, Empty, Button } from "@bitcine/cs-theme"
import { Collapse } from "react-collapse"
import { ReactComponent as EmptyIcon } from "@src/media/icons/empty.inline.svg"

const Project = ({ project, list, updateCampaignFormStep, disabledIds }) => (
  <article
    css={`
      opacity: ${list.opened_project_id && list.opened_project_id !== project._id ? 0.2 : 1};
      transition: opacity 0.3s linear;
    `}
    className='mt2 box-shadow bg-white rounded'>
    <Button
      css={`
        height: auto;
      `}
      className='col-12 block link'
      onClick={() => {
        updateCampaignFormStep("content", {
          available_list: {
            ...list,
            opened_project_id: list.opened_project_id === project._id ? "" : project._id
          }
        })
      }}>
      <div className='flex items-center p2'>
        <div
          css={`
            height: 44px;
            width: 32px;
            background-image: ${project.active_poster_url ? `url(${project.active_poster_url})` : ""};
          `}
          className='bg-gray-5 rounded box-shadow bg-cover bg-center mr2'/>
        <div className='left-align flex-auto'>
          <strong className='block'>{project.title}</strong>
          <i className='small muted'>
            {project.digital_cinema_packages.length} Available&nbsp;-&nbsp;
            {
              project.digital_cinema_packages.filter(
                dcp => list.selected_content_ids.includes(dcp._id) || disabledIds.includes(dcp._id)
              ).length
            }{" "}
            Selected
          </i>
        </div>
        <span className='material-icons'>
          {list.opened_project_id === project._id ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </span>
      </div>
    </Button>
    <Collapse isOpened={list.opened_project_id === project._id}>
      <div className='p2'>
        {project.digital_cinema_packages.length === 0 && (
          <Empty icon={<EmptyIcon/>} title='No DCPs' text='Try uploading a DCP to this project to get started'/>
        )}
        {project.digital_cinema_packages.map(dcp => (
          <label
            css={`
              word-break: break-all;
              :hover {
                background-color: #f9f9ff;
              }
            `}
            className={`flex items-center p1 rounded bg-gray-6 border border-gray-4 mb1 pointer ${
              disabledIds.includes(dcp._id) ? "muted not-allowed" : ""
            }`}
            key={dcp._id}>
            <Checkbox
              checked={list.selected_content_ids.includes(dcp._id) || disabledIds.includes(dcp._id)}
              disabled={disabledIds.includes(dcp._id)}
              onChange={() => {
                updateCampaignFormStep("content", {
                  available_list: {
                    ...list,
                    selected_content_ids: list.selected_content_ids.includes(dcp._id)
                      ? list.selected_content_ids.filter(id => id !== dcp._id)
                      : [...list.selected_content_ids, dcp._id]
                  }
                })
              }}/>
            <div>{dcp.name}</div>
          </label>
        ))}
      </div>
    </Collapse>
  </article>
)

const mapStateToProps = state => ({
  list: state.campaign_form.steps.content.available_list,
  disabledIds: state.campaign_form.model.contents.map(({ _id }) => _id)
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch)
})

Project.defaultProps = {
  project: {
    title: ""
  }
}

Project.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string
  }).isRequired,
  disabledIds: PropTypes.array.isRequired,
  updateCampaignFormStep: PropTypes.func.isRequired,
  list: PropTypes.shape({
    selected_content_ids: PropTypes.array.isRequired,
    opened_project_id: PropTypes.string
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Project)
