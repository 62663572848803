export const GET_BILLING = "GET_BILLING"
export const GET_BILLING_PENDING = "GET_BILLING_PENDING"
export const GET_BILLING_FAILED = "GET_BILLING_FAILED"
export const GET_BILLING_FULFILLED = "GET_BILLING_FULFILLED"

export const UPDATE_BILLING = "UPDATE_BILLING"

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD"
export const ADD_PAYMENT_METHOD_PENDING = "ADD_PAYMENT_METHOD_PENDING"
export const ADD_PAYMENT_METHOD_FULFILLED = "ADD_PAYMENT_METHOD_FULFILLED"
export const ADD_PAYMENT_METHOD_FAILED = "ADD_PAYMENT_METHOD_FAILED"
