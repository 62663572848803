import dayjs from "dayjs"

const TODAY = dayjs().day()
const WEDNESDAY = 3
const THURSDAY = 4
const FRIDAY = 5

const getMinimumDay = (type, starts, ends) => {
  if (type === "starts_at") {
    if ([WEDNESDAY, THURSDAY, FRIDAY].includes(TODAY)) {
      return dayjs()
        .add(5, "day")
        .toDate()
    }
    return dayjs()
      .add(3, "day")
      .toDate()
  } else {
    if (starts) {
      return dayjs(starts)
        .add(1, "day")
        .toDate()
    } else {
      return dayjs().toDate()
    }
  }
}

export default getMinimumDay
