import {
  UPDATE_CAMPAIGNS,
  GET_CAMPAIGNS_PENDING,
  GET_CAMPAIGNS_FULFILLED,
  GET_CAMPAIGNS_FAILED
} from "@actions/campaigns/"
import { DELETE_CAMPAIGN_PENDING, DELETE_CAMPAIGN_FAILED, DELETE_CAMPAIGN_FULFILLED } from "@actions/campaign/"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_CAMPAIGNS:
      return {
        ...state,
        ...action.payload
      }

    case GET_CAMPAIGNS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_CAMPAIGNS_FAILED:
      return {
        ...state,
        status: "READY",
        list: []
      }

    case GET_CAMPAIGNS_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload
      }

    case DELETE_CAMPAIGN_PENDING:
      console.log(action)
      return {
        ...state,
        deleting_campaign_id: action.meta._id
      }

    case DELETE_CAMPAIGN_FAILED:
      return {
        ...state,
        deleting_campaign_id: null
      }

    case DELETE_CAMPAIGN_FULFILLED:
      return {
        ...state,
        deleting_campaign_id: null,
        list: state.list.filter(({ _id }) => _id !== action.meta._id)
      }

    default:
      return state
  }
}
