import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Button } from "@bitcine/cs-theme"
import { addContentToCampaignForm } from "@api/campaign_form/"

const Footer = ({ list, addContentToCampaignForm }) => (
  <div className='right-align pt3'>
    <Button
      disabled={!list.selected_content_ids.length || list.status === "PENDING"}
      onClick={addContentToCampaignForm}>
      Add Selected Content
    </Button>
  </div>
)

const mapStateToProps = state => ({
  list: state.campaign_form.steps.content.available_list
})

const mapDispatchToProps = dispatch => ({
  addContentToCampaignForm: bindActionCreators(addContentToCampaignForm, dispatch)
})

Footer.propTypes = {
  list: PropTypes.shape({
    selected_content_ids: PropTypes.array.isRequired
  }).isRequired,
  addContentToCampaignForm: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
