import React from "react"
import PropTypes from "prop-types"
import 'styled-components/macro'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { has_error: false }
  }
  componentDidCatch(error, info) {
    this.setState({ has_error: true })
  }
  render() {
    return this.state.has_error ? (
      <div className='pt4 col-12 my3 center'>
        <span
          css={`
            font-size: 48px;
          `}
          className='material-icons red large-icon block'>
          error
        </span>
        <h4>Error</h4>
        <p className='max-width-1 mx-auto'>
          An internal error occurred. If this problem persists please contact support. This error has been logged
          automatically.
        </p>
      </div>
    ) : (
      this.props.children
    )
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}

export default ErrorBoundary
