import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Switch, Route } from "react-router-dom"
import environmnent from "@src/helpers/environment"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { getCampaignForm, resetCampaignForm } from "@api/campaign_form/"
import { Status } from "@bitcine/cs-theme"
import Steps from "./components/steps"
import Footer from "./components/footer"
import Header from "./components/header"
import Content from "./pages/content/index"
import Details from "./pages/details/index"
import Cinemas from "./pages/cinemas/index"
import Review from "./pages/review/index"

export class CampaignForm extends React.Component {
  componentDidMount() {
    if (!environmnent.is("development") && this.props.step !== "details") {
      this.props.history.replace("/campaigns/form/details")
    }
    if (this.props.id) {
      this.props.getCampaignForm()
    }
  }
  componentWillUnmount() {
    this.props.resetCampaignForm()
  }
  render() {
    return (
      <Route
        render={({ location }) => (
          <section className='pb4'>
            <Status error={this.props.status === "ERROR"} pending={this.props.status === "PENDING"}>
              <Steps step={this.props.step}/>
              <Header step={this.props.step}/>
              <Footer step={this.props.step}/>
              <TransitionGroup>
                <CSSTransition key={location.key} classNames='fadeTranslate' timeout={300}>
                  <Switch location={this.props.location}>
                    <Route path='/campaigns/form/details' component={Details}/>
                    <Route path='/campaigns/form/content' component={Content}/>
                    <Route path='/campaigns/form/cinemas' component={Cinemas}/>
                    <Route path='/campaigns/form/review' component={Review}/>
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </Status>
          </section>
        )}/>
    )
  }
}

const mapStateToProps = (state, props) => ({
  status: state.campaign_form.utils.status,
  id: state.campaign_form.model._id,
  step: props.location.pathname.split("/").pop()
})

const mapDispatchToProps = dispatch => ({
  getCampaignForm: bindActionCreators(getCampaignForm, dispatch),
  resetCampaignForm: bindActionCreators(resetCampaignForm, dispatch)
})

CampaignForm.defaultProps = {
  id: null
}

CampaignForm.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  status: PropTypes.string.isRequired,
  getCampaignForm: PropTypes.func.isRequired,
  resetCampaignForm: PropTypes.func.isRequired,
  id: PropTypes.string,
  step: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignForm)
