import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { updateCampaigns } from "@api/campaigns/"
import { Link } from "react-router-dom"
import { Button } from "@bitcine/cs-theme"

export const CampaignListHeader = ({ updateCampaigns, campaigns }) => (
  <div className='flex items-center'>
    <h4 className='flex-auto'>Campaigns</h4>
    <input
      className='mr2 cs-input'
      css={`
        height: 34px;
      `}
      placeholder='Search campaigns...'
      value={campaigns.search_term}
      onChange={e => updateCampaigns({ search_term: e.target.value })}/>
    <Link to='/campaigns/form/details'>
      <Button className='small'>Create Campaign</Button>
    </Link>
  </div>
)

const mapStateToProps = state => ({
  campaigns: state.campaigns
})

const mapDispatchToProps = dispatch => ({
  updateCampaigns: bindActionCreators(updateCampaigns, dispatch)
})

CampaignListHeader.propTypes = {
  updateCampaigns: PropTypes.func.isRequired,
  campaigns: PropTypes.shape({
    search_term: PropTypes.string.isRequired
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignListHeader)
