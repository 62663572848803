import {
  GET_CAMPAIGN_FORM_PENDING,
  GET_CAMPAIGN_FORM_FAILED,
  GET_CAMPAIGN_FORM_FULFILLED,
  POST_CAMPAIGN_FORM_PENDING,
  POST_CAMPAIGN_FORM_FAILED,
  POST_CAMPAIGN_FORM_FULFILLED,
  PUT_CAMPAIGN_FORM_PENDING,
  PUT_CAMPAIGN_FORM_FAILED,
  PUT_CAMPAIGN_FORM_FULFILLED,
  RESET_CAMPAIGN_FORM,
  SUBMIT_NEW_CAMPAIGN_PENDING,
  SUBMIT_NEW_CAMPAIGN_FULFILLED,
  SUBMIT_NEW_CAMPAIGN_FAILED
} from "@actions/campaign_form/"
import INITIAL_STATE from "../config"

export default function reducer(state = INITIAL_STATE.utils, action = {}) {
  switch (action.type) {
    case RESET_CAMPAIGN_FORM:
      return INITIAL_STATE.utils

    case GET_CAMPAIGN_FORM_PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_CAMPAIGN_FORM_FULFILLED:
      return {
        ...state,
        status: "READY"
      }

    case SUBMIT_NEW_CAMPAIGN_PENDING:
    case PUT_CAMPAIGN_FORM_PENDING:
    case POST_CAMPAIGN_FORM_PENDING:
      return {
        ...state,
        disable_navigation: true
      }

    case SUBMIT_NEW_CAMPAIGN_FAILED:
    case GET_CAMPAIGN_FORM_FAILED:
    case PUT_CAMPAIGN_FORM_FAILED:
    case POST_CAMPAIGN_FORM_FAILED:
      return {
        ...state,
        disable_navigation: false,
        status: "ERROR"
      }

    case SUBMIT_NEW_CAMPAIGN_FULFILLED:
    case PUT_CAMPAIGN_FORM_FULFILLED:
    case POST_CAMPAIGN_FORM_FULFILLED:
      return {
        ...state,
        disable_navigation: false
      }
    default:
      return state
  }
}
