import React from "react"
import "styled-components/macro"
import fadeIn from "@src/css/exports/fade_in"
import Summary from "./components/summary"
import Payment from "./components/payment/index"

class Review extends React.Component {
  render() {
    return (
      <div className='flex items-start'>
        <div
          css={`
            ${fadeIn()};
          `}
          className='flex-auto pr1'>
          <Summary/>
        </div>
        <div
          css={`
            width: 320px;
            min-width: 480px;
            ${fadeIn()};
          `}
          className='col-5 pl2'>
          <Payment/>
        </div>
      </div>
    )
  }
}

export default Review
