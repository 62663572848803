import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Select from "./select"

const LoadSavedCampaign = ({ show }) => (show ? <Select/> : null)

const mapStateToProps = state => ({
  show: state.campaign_form.steps.cinemas.saved_templates.show
})

LoadSavedCampaign.propTypes = {
  show: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  null
)(LoadSavedCampaign)
