import React from "react"
import PropTypes from "prop-types"
import "styled-components/macro"
import { ReactComponent as CineSend } from "@src/media/logos/logo-grayscale.inline.svg"

const styles = `
  display: block;
  width: 100%;
  height: 100%;
  max-width: 60px;
  max-height: 55px;
  opacity: 0.6;
`

const imgs = {
  cinesend: <CineSend css={styles}/>
}
const CinemaVendor = ({ vendor }) => imgs[vendor.toLowerCase()] || vendor

CinemaVendor.propTypes = {
  vendor: PropTypes.string.isRequired
}

export default CinemaVendor
