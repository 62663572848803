import { AUTH_CHECK_FAILED, AUTH_CHECK_FULFILLED } from "@actions/auth/index"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AUTH_CHECK_FAILED:
      return {
        status: "GUEST"
      }

    case AUTH_CHECK_FULFILLED:
      return {
        status: "AUTHENTICATED",
        ...action.payload.user
      }

    default:
      return state
  }
}
