import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import {
  getTemplates,
  applySavedTemplateToNewCampaign,
  resetNewCampaignLoadTemplate,
  updateCampaignFormStep
} from "@api/campaign_form/"
import { Modal, Status, Table, Button } from "@bitcine/cs-theme"
import { ReactComponent as EmptyIcon } from "@src/media/icons/empty.inline.svg"
import dayjs from "dayjs"

class SaveNewTemplate extends React.Component {
  componentDidMount() {
    this.props.getTemplates()
  }
  toggleChecked = data => {
    this.props.updateCampaignFormStep("cinemas", {
      saved_templates: {
        ...this.props.templates,
        selected_template_ids: this.props.templates.selected_template_ids.includes(data._id)
          ? this.props.templates.selected_template_ids.filter(id => id !== data._id)
          : [...this.props.templates.selected_template_ids, data._id]
      }
    })
  }
  toggleHeaderChecked = () => {
    this.props.updateCampaignFormStep("cinemas", {
      saved_templates: {
        ...this.props.templates,
        selected_template_ids: this.props.templates.selected_template_ids.length
          ? []
          : this.filter().map(({ _id }) => _id)
      }
    })
  }
  filter = () => {
    return this.props.templates.list.filter(template => {
      const name = template.name || ""
      return name
        .trim()
        .toLowerCase()
        .includes(this.props.templates.search_term.trim().toLowerCase())
    })
  }
  render() {
    const { templates, updateCampaignFormStep, applySavedTemplateToNewCampaign } = this.props
    const list = this.filter()
    return (
      <Modal title='Load saved template' open={templates.show} onClose={this.props.resetNewCampaignLoadTemplate}>
        <Status pending={templates.status === "PENDING"} error={templates.status === "ERROR"}>
          <React.Fragment>
            <div className='flex items-center justify-between mb3'>
              <strong>All Templates</strong>
              <input
                css={`
                  height: 34px;
                `}
                className='cs-input'
                placeholder='Search saved templates'
                value={templates.search_term}
                onChange={e =>
                  updateCampaignFormStep("cinemas", { saved_templates: { ...templates, search_term: e.target.value } })
                }/>
            </div>
            <Table
              widths={["auto", "100"]}
              header={{
                columns: [{ text: "Template Name" }, { text: "" }],
                checkbox: {
                  checked: templates.selected_template_ids.length > 0,
                  intermediate: templates.selected_template_ids.length !== list.length,
                  onChange: value => this.toggleHeaderChecked()
                }
              }}
              body={{
                data: list,
                empty: {
                  icon: <EmptyIcon/>,
                  title: templates.search_term ? "No content found" : "No templates found",
                  text: templates.search_term ? "Try your search again" : "Try adding a template to get started"
                },
                row: {
                  onClick: (e, data) => this.toggleChecked(data),
                  checkbox: {
                    checked: data => templates.selected_template_ids.includes(data._id),
                    onChange: data => this.toggleChecked(data)
                  },
                  render: [
                    data => (
                      <div>
                        {data.name || "Unnamed Template"}
                        <small className='block muted'>Created: {dayjs(data.created_at).format("MMM Do, YYYY")}</small>
                      </div>
                    ),
                    data => ""
                  ]
                }
              }}/>
          </React.Fragment>
          <div className='right-align mt3'>
            <Button
              disabled={templates.status !== "READY" || !templates.selected_template_ids.length}
              onClick={applySavedTemplateToNewCampaign}>
              Load Template{templates.selected_template_ids.length > 0 ? "s" : ""}
            </Button>
          </div>
        </Status>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  templates: state.campaign_form.steps.cinemas.saved_templates
})

const mapDispatchToProps = dispatch => ({
  getTemplates: bindActionCreators(getTemplates, dispatch),
  applySavedTemplateToNewCampaign: bindActionCreators(applySavedTemplateToNewCampaign, dispatch),
  resetNewCampaignLoadTemplate: bindActionCreators(resetNewCampaignLoadTemplate, dispatch),
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch)
})

SaveNewTemplate.propTypes = {
  applySavedTemplateToNewCampaign: PropTypes.func.isRequired,
  getTemplates: PropTypes.func.isRequired,
  updateCampaignFormStep: PropTypes.func.isRequired,
  resetNewCampaignLoadTemplate: PropTypes.func.isRequired,
  templates: PropTypes.shape({
    search_term: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    selected_template_ids: PropTypes.array.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveNewTemplate)
