import React from "react"
import PropTypes from "prop-types"
import "styled-components/macro"
import { Checkbox } from "@bitcine/cs-theme"
import { ReactComponent as Logo } from "@src/media/logos/logo-whitered.inline.svg"

const PostPay = ({ onSelect, selected }) => (
  <label
    css={`
      background: gray;
      :hover {
        box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.63);
      }
      .material-icons {
        color: white;
      }
      * {
        user-select: none;
      }
    `}
    className='flex items-center p2 rounded mt2 white pointer no-select'>
    <Checkbox checked={selected} onChange={onSelect}/>
    <div
      css={`
        font-size: 18px;
      `}
      className='flex-auto pl3 pr2'>
      Post Pay
    </div>
    <Logo
      css={`
        width: 100px;
        height: 20px;
      `}/>
  </label>
)

PostPay.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
}

export default PostPay
