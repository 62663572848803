import React from "react"
import PropTypes from "prop-types"
import CinemaAddress from "@src/pages/campaigns/form/components/cinema_address"

const CinemaName = ({ cinema }) => (
  <div>
    <strong>{cinema.name}</strong>
    <small className='block muted'>
      <CinemaAddress cinema={cinema}/>
    </small>
  </div>
)

CinemaName.propTypes = {
  cinema: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

export default CinemaName
