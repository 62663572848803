const INITIAL_STATE = {
  search_term: "",
  sort_by: "created_at",
  sort_direction: "DESC",
  status: "PENDING",
  list: [],
  deleting_campaign_id: null
}

export default INITIAL_STATE
