import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import dayjs from "dayjs"
import centsToDollars from "@src/helpers/cents_to_dollars"
import CampaignReviewList from "./campaign_review_list"
import CampaignReviewForm from "./campaign_review_form"

const Summary = ({ model, campaign }) => (
  <div className='bg-white rounded box-shadow p3'>
    <h4>Summary</h4>
    <CampaignReviewForm/>
    <CampaignReviewList
      title='Selected DCPs'
      to='/campaigns/form/content'
      list={model.contents}
      render={item => (
        <div key={item._id} className='mt1'>
          {item.name}
        </div>
      )}/>
    <CampaignReviewList
      title='Selected Cinemas'
      to='/campaigns/form/cinemas'
      list={model.cinemas}
      render={item => (
        <div key={item._id} className='flex items-center mt1'>
          <div className='flex-auto'>
            {item.name}
            <small className='block muted'>
              {model.cinema_overrider_dates_map && model.cinema_overrider_dates_map[item._id] ? (
                <React.Fragment>
                  {dayjs(model.cinema_overrider_dates_map[item._id].starts_at).format("MMM Do, YYYY")} to{" "}
                  {dayjs(model.cinema_overrider_dates_map[item._id].ends_at).format("MMM Do, YYYY")}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {dayjs(campaign.starts_at).format("MMM Do, YYYY")} to {dayjs(campaign.ends_at).format("MMM Do, YYYY")}
                </React.Fragment>
              )}{" "}
              <br/>
              {item.address}, {item.city}, {item.state ? item.state.toUpperCase() : ""}, {item.country_code}
            </small>
          </div>
          <div className='pl1'>
            {item.shipping_cost_currency_symbol}
            {centsToDollars(item.shipping_cost)}
            &nbsp;
            {item.shipping_cost_currency_code}
          </div>
        </div>
      )}/>
  </div>
)

const mapStateToProps = state => ({
  model: state.campaign_form.model,
  campaign: state.campaign_form.model
})

Summary.propTypes = {
  model: PropTypes.shape({
    cinemas: PropTypes.array.isRequired,
    contents: PropTypes.array.isRequired,
    cinema_overrider_dates_map: PropTypes.object
  }).isRequired,
  campaign: PropTypes.shape({
    starts_at: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired,
    cinema_overrider_dates_map: PropTypes.array
  }).isRequired
}

export default connect(
  mapStateToProps,
  null
)(Summary)
