import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getCampaign, onPusherCampaignCinemaUpdatedEvent } from "@api/campaign/"
import { Status, Table } from "@bitcine/cs-theme"
import Pusher from "@src/components/pusher"
import CinemaName from "@src/components/table/cinema_name"
import CinemaDates from "@src/components/table/cinema_dates"
import CinemaStatus from "@src/components/table/cinema_status"

class Campaign extends React.Component {
  componentDidMount() {
    this.props.getCampaign(this.props.match.params.campaignID)
  }
  componentDidUpdate(props) {
    if (props.match.params.campaignID !== this.props.match.params.campaignID) {
      this.props.getCampaign(this.props.match.params.campaignID)
    }
  }
  render() {
    const { campaign } = this.props
    return (
      <Status pending={campaign.status === "PENDING"} error={campaign.status === "ERROR"}>
        <React.Fragment>
          <h4>{campaign.name}</h4>
          <Pusher
            channel={`private-App.Campaigns.${campaign._id}.CinemaUpdated`}
            event='Modules\Campaign\Events\CinemaUpdated'
            onError={data => null}
            onUpdate={this.props.onPusherCampaignCinemaUpdatedEvent}>
            <Table
              className='my3'
              widths={["auto", "220", "380"]}
              header={{
                columns: [{ text: "Destination" }, { text: "Date" }, { text: "Status" }]
              }}
              body={{
                data: campaign.cinemas,
                row: {
                  render: [
                    data => <CinemaName cinema={data}/>,
                    data => <CinemaDates cinema={data} model={campaign}/>,
                    data => <CinemaStatus delivery={campaign.cinema_meta[data._id]} cinema={data}/>
                  ]
                }
              }}/>
          </Pusher>
        </React.Fragment>
      </Status>
    )
  }
}

Campaign.propTypes = {
  campaign: PropTypes.shape({
    status: PropTypes.string.isRequired,
    name: PropTypes.string,
    cinemas: PropTypes.array,
    cinema_meta: PropTypes.object
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignID: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  getCampaign: PropTypes.func.isRequired,
  onPusherCampaignCinemaUpdatedEvent: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  campaign: state.campaign
})

const mapDispatchToProps = dispatch => ({
  getCampaign: bindActionCreators(getCampaign, dispatch),
  onPusherCampaignCinemaUpdatedEvent: bindActionCreators(onPusherCampaignCinemaUpdatedEvent, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaign)
