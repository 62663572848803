import React from "react"
import PropTypes from "prop-types"
import "styled-components/macro"
import { Checkbox } from "@bitcine/cs-theme"
import { CREDIT_CARD_BACKGROUND_COLORS } from "./config"
import isCardExpired from "./is_card_expired"
import Amex from "./images/amex-logo.png"
import Mastercard from "./images/mastercard-logo.png"
import Visa from "./images/visa-logo.png"

const images = {
  visa: Visa,
  mastercard: Mastercard,
  american_express: Amex
}

const Card = ({ card, onSelect, selected }) => (
  <label
    css={`
      background: ${CREDIT_CARD_BACKGROUND_COLORS[card.brand.replace(" ", "_").toLowerCase()]};
      :hover {
        box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.63);
      }
      .material-icons {
        color: white;
      }
      * {
        user-select: none;
      }
    `}
    className='flex items-center p2 rounded mt2 white pointer no-select'>
    {isCardExpired(card) ? (
      "Card Expired"
    ) : (
      <Checkbox disabled={isCardExpired(card)} checked={selected} onChange={onSelect}/>
    )}
    <div
      css={`
        font-size: 18px;
      `}
      className='flex-auto pl3 pr2'>
      {card.brand === "American Express" ? `**** ****** *${card.last4}` : `**** **** **** ${card.last4}`}
    </div>
    <img
      css={`
        max-width: 50px;
        max-height: 25px;
      `}
      src={images[card.brand.replace(" ", "_").toLowerCase()]}
      alt={card.brand}/>
  </label>
)

Card.propTypes = {
  card: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
}

export default Card
