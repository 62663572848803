import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { updateCampaignFormModel, resetNewCampaignFormStep } from "@api/campaign_form/"
import DayPicker from "react-day-picker/DayPickerInput"
import dayjs from "dayjs"
import fadeIn from "@src/css/exports/fade_in"
import getMinimumDay from "../../functions/minimum_day"
import getMaximumDay from "../../functions/maximum_day"

export class Details extends React.Component {
  componentWillUmount() {
    this.props.resetNewCampaignFormStep("details")
  }
  render() {
    const { newCampaign, updateCampaignFormModel } = this.props
    return (
      <form
        css={`
          ${fadeIn()}
        `}
        className='rounded box-shadow p3 my3 bg-white max-width-2 mx-auto'>
        <div className='mb2'>
          <label htmlFor='title'>Campaign Name</label>
          <input
            id='title'
            value={newCampaign.model.name}
            autoFocus
            autoComplete='off'
            onChange={e => updateCampaignFormModel({ name: e.target.value })}
            className={`cs-input block col-12 ${newCampaign.steps.details.validation_errors.includes("name") ? "error" : ""}`}/>
          {newCampaign.steps.details.validation_errors.includes("name") && (
            <small className='red block'>This field is required.</small>
          )}
        </div>
        <div className='flex items-start'>
          {["starts_at", "ends_at"].map((type, index) => (
            <div
              key={type}
              css={`
                .DayPickerInput {
                  width: 100%;
                }
              `}
              className={`col-6 ${index === 0 ? "pr1" : "pl1"}`}>
              <label className='capitalize' htmlFor={type}>
                {type.replace("s_at", "")} date
              </label>
              <DayPicker
                key={type}
                value={newCampaign.model[type] ? dayjs(newCampaign.model[type]).toDate() : ""}
                inputProps={{
                  className: `cs-input col-12 block ${
                    newCampaign.steps.details.validation_errors.includes(type) ? "error" : ""
                  }`,
                  id: type,
                  autoComplete: "off"
                }}
                id={type}
                dayPickerProps={{
                  disabledDays: {
                    before: getMinimumDay(type, newCampaign.model.starts_at, newCampaign.model.ends_at),
                    after: getMaximumDay(type, newCampaign.model.starts_at, newCampaign.model.ends_at)
                  }
                }}
                placeholder=''
                formatDate={date => dayjs(date).format("MMM Do, YYYY")}
                onDayChange={date => updateCampaignFormModel({ [type]: dayjs(date).format("YYYY-MM-DD HH:mm:ss") })}/>
              {newCampaign.steps.details.validation_errors.includes(type) && (
                <small className='red block'>This field is required.</small>
              )}
            </div>
          ))}
        </div>
      </form>
    )
  }
}

Details.propTypes = {
  newCampaign: PropTypes.shape({
    model: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      starts_at: PropTypes.string.isRequired,
      ends_at: PropTypes.string.isRequired
    }).isRequired,
    details: PropTypes.shape({
      validation_errors: PropTypes.array.isRequired
    })
  }).isRequired,
  updateCampaignFormModel: PropTypes.func.isRequired,
  resetNewCampaignFormStep: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  newCampaign: state.campaign_form
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormModel: bindActionCreators(updateCampaignFormModel, dispatch),
  resetNewCampaignFormStep: bindActionCreators(resetNewCampaignFormStep, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Details)
