import React from "react"
import PropTypes from "prop-types"
import centsToDollars from "@src/helpers/cents_to_dollars"

const CinemaDeliveryCost = ({ cinema }) => (
  <React.Fragment>
    {`
${cinema.shipping_cost_currency_symbol}
${centsToDollars(cinema.shipping_cost)}
${cinema.shipping_cost_currency_code}
`}
  </React.Fragment>
)

CinemaDeliveryCost.propTypes = {
  cinema: PropTypes.shape({
    shipping_cost_currency_symbol: PropTypes.string.isRequired,
    shipping_cost: PropTypes.number.isRequired,
    shipping_cost_currency_code: PropTypes.string.isRequired
  }).isRequired
}

export default CinemaDeliveryCost
