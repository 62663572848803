import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

const CinemaDeliveryCost = ({ cinema, model }) => (
  <React.Fragment>
    {model.cinema_overrider_dates_map && model.cinema_overrider_dates_map[cinema._id]
      ? `${dayjs(model.cinema_overrider_dates_map[cinema._id].starts_at).format("MMM Do")} - ${dayjs(
        model.cinema_overrider_dates_map[cinema._id].ends_at
      ).format("MMM Do, YYYY")}`
      : `${dayjs(model.starts_at).format("MMM Do")} - ${dayjs(model.ends_at).format("MMM Do, YYYY")}`}
  </React.Fragment>
)

CinemaDeliveryCost.propTypes = {
  cinema: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired,
  model: PropTypes.shape({
    ends_at: PropTypes.string.isRequired,
    starts_at: PropTypes.string.isRequired,
    cinema_overrider_dates_map: PropTypes.object
  }).isRequired
}

export default CinemaDeliveryCost
