import { LOG_OUT, AUTH_CHECK } from "@actions/auth/"
import { GLOBALS } from "@src/config"
import { post, get } from "@src/helpers/fetch"

export const authCheck = () => dispatch => {
  const callbackURL = GLOBALS.URL + window.location.pathname
  dispatch(
    get(
      AUTH_CHECK,
      `auth/check`,
      null,
      error => {
         window.location.replace(`${GLOBALS.ACCOUNT_REDIRECT_LOGIN_URL}?redirect=${callbackURL}`)
      }
    )
  )
}

export const logOut = () => dispatch =>
  dispatch(post(LOG_OUT, `auth/logout`, null, () => window.location.replace(GLOBALS.ACCOUNT_REDIRECT_LOGIN_URL)))

