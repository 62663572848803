import React from "react"
import PropTypes from "prop-types"

const CinemaAddress = ({ cinema }) => (
  <div>
    {cinema.address ? `${cinema.address}, ` : ""}
    {cinema.city ? `${cinema.city}, ` : ""}
    {cinema.state ? `${cinema.state.toUpperCase()}, ` : ""}
    {cinema.country ? `${cinema.country}` : ""}
  </div>
)

CinemaAddress.propTypes = {
  cinema: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string
  }).isRequired
}

export default CinemaAddress
