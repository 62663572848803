import { UPDATE_CAMPAIGNS, GET_CAMPAIGNS } from "@actions/campaigns/"
import { get } from "@helpers/fetch"

/**
 * Updates the campaigns state in redux
 *
 * @param {Object} [payload] This will be the object passed
 * through from the to update. Any keys that currently
 * exists on state.campaigns will be overriden.
 *
 * @returns {Function} A redux dispatch
 */

export const updateCampaigns = payload => dispatch => dispatch({ type: UPDATE_CAMPAIGNS, payload })

/**
 * Fetches the campaigns from the server
 *
 * @returns {Function} A redux dispatch
 */

export const getCampaigns = () => dispatch => dispatch(get(GET_CAMPAIGNS, "campaigns"))
