import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { updateCampaignFormStep, postNewTemplate, resetNewCampaignNewTemplate } from "@api/campaign_form/"
import { Modal, Status, Button } from "@bitcine/cs-theme"

const SaveNewTemplate = ({ newTemplate, updateCampaignFormStep, resetNewCampaignNewTemplate, postNewTemplate }) => (
  <Modal title='Save new template' open={newTemplate.show} onClose={resetNewCampaignNewTemplate}>
    <Status pending={newTemplate.status === "PENDING"} error={newTemplate.status === "ERROR"}>
      <form>
        <label htmlFor='template_name'>Template name</label>
        <input
          autoFocus
          value={newTemplate.name}
          className='block col-12 cs-input'
          onChange={e => updateCampaignFormStep("cinemas", { new_template: { ...newTemplate, name: e.target.value } })}/>
      </form>
      <div className='right-align mt3'>
        <Button disabled={!newTemplate.name} onClick={postNewTemplate}>
          Save Template
        </Button>
      </div>
    </Status>
  </Modal>
)

const mapStateToProps = state => ({
  newTemplate: state.campaign_form.steps.cinemas.new_template
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch),
  postNewTemplate: bindActionCreators(postNewTemplate, dispatch),
  resetNewCampaignNewTemplate: bindActionCreators(resetNewCampaignNewTemplate, dispatch)
})

SaveNewTemplate.propTypes = {
  updateCampaignFormStep: PropTypes.func.isRequired,
  postNewTemplate: PropTypes.func.isRequired,
  resetNewCampaignNewTemplate: PropTypes.func.isRequired,
  newTemplate: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveNewTemplate)
