export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const DELETE_CAMPAIGN_PENDING = "DELETE_CAMPAIGN_PENDING"
export const DELETE_CAMPAIGN_FAILED = "DELETE_CAMPAIGN_FAILED"
export const DELETE_CAMPAIGN_FULFILLED = "DELETE_CAMPAIGN_FULFILLED"

export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const GET_CAMPAIGN_PENDING = "GET_CAMPAIGN_PENDING"
export const GET_CAMPAIGN_FAILED = "GET_CAMPAIGN_FAILED"
export const GET_CAMPAIGN_FULFILLED = "GET_CAMPAIGN_FULFILLED"

export const PUSHER_CAMPAIGN_CINEMA_UPDATED_EVENT = "PUSHER_CAMPAIGN_CINEMA_UPDATED_EVENT"
