import React from "react"
import PropTypes from "prop-types"
import "styled-components/macro"
import { Modal, Button } from "@bitcine/cs-theme"

const STATUS_OPTS = {
  completed: {
    icon: "check_circle",
    color: "green",
    text: "Completed"
  },
  delivered: {
    icon: "check_circle",
    color: "green",
    text: "Delivered"
  },
  submitted: {
    icon: "access_time",
    color: "orange",
    text: "Submitted"
  },
  in_transit: {
    icon: "local_shipping",
    color: "orange",
    text: "In Transit"
  },
  error: {
    icon: "error",
    color: "red",
    text: "Error"
  },
  queued: {
    icon: "access_time",
    color: "orange",
    text: "Submitted"
  }
}

class CinemaStatus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view_errors: false
    }
  }
  toggleErrorsVisibility = () => this.setState(state => ({ view_errors: !state.view_errors }))
  render() {
    const delivery = this.props.delivery
    if (!delivery) {
      return <span className='muted'>N/A</span>
    }
    const status = delivery.status ? delivery.status.toLowerCase() : null
    if (status === "running") {
      return (
        <div className='col-12 pr3'>
          <strong>Downloading... {delivery.progress}%</strong>
          <progress
            css={`
              height: 3px;
              margin: 0;
            `}
            className='col-12'
            max={100}
            value={delivery.progress}/>
        </div>
      )
    } else {
      const opts = STATUS_OPTS[status.split(" ").join("_")]
      if (!opts) {
        return <span className='muted'>N/A</span>
      }
      return (
        <div
          css={`
            color: ${opts.color};
          `}
          className='flex items-center'>
          <span
            css={`
              font-size: 1.2em;
              margin-right: 0.25em;
            `}
            className='material-icons'>
            {opts.icon}
          </span>
          <div>
            <strong>{opts.text}</strong>
            {status === "error" && Array.isArray(delivery.errors) && delivery.errors.length && (
              <React.Fragment>
                <Button
                  onClick={this.toggleErrorsVisibility}
                  css={`
                    height: auto;
                    font-size: 0.75em;
                    text-decoration: underline;
                    font-weight: 400;
                  `}
                  className='link ml1'>
                  View Errors
                </Button>
                <Modal title='Errors' onClose={this.toggleErrorsVisibility} open={this.state.view_errors}>
                  <React.Fragment>
                    {delivery.errors.map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                  </React.Fragment>
                </Modal>
              </React.Fragment>
            )}
          </div>
        </div>
      )
    }
  }
}

CinemaStatus.defaultProps = {
  delivery: {
    status: "Submitted"
  }
}

CinemaStatus.propTypes = {
  delivery: PropTypes.shape({
    status: PropTypes.string,
    progress: PropTypes.number,
    errors: PropTypes.array
  })
}

export default CinemaStatus
