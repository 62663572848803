const client = `https://bookings.cinesend.com`
const accountsClient = `https://accounts.cinesend.com`
const api = `https://api.cinesend.com/api`
const stripeKey = `pk_live_Wl0SxWj9MQR0cUz2bLGMAeZV`

export const GLOBALS = {
  PORT: 8008,
  BUILD_ENV: 'production',
  URL: client,
  API_URL: api,
  ACCOUNT_REDIRECT_LOGIN_URL: `${accountsClient}/login`,
  STRIPE_KEY: stripeKey,
  SETTINGS_URL: `${accountsClient}/settings/profile`,
  PUSHER_KEY: 'd9783f156b21b5e86f7f',
  PUSHER_DEBUG: false
}