import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "styled-components/macro"
import { Button } from "@bitcine/cs-theme"

class CampaignReviewList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
    this.MAX_LIST_LENGTH = 3
  }
  render() {
    return (
      <div>
        <div className='flex items-center justify-between mt3'>
          <strong>{this.props.title}</strong>
          <Link className='underline' to={this.props.to}>
            Edit
          </Link>
        </div>
        {this.props.list
          .filter((item, index) => (this.state.open ? item : index < this.MAX_LIST_LENGTH))
          .map(item => this.props.render(item))}
        {!this.state.open && this.props.list.length > this.MAX_LIST_LENGTH && (
          <Button
            onClick={() => this.setState({ open: true })}
            css={`
              height: auto;
              font-size: 0.8em;
            `}
            className='link mt1 regular underline'>
            View {this.props.list.length - this.MAX_LIST_LENGTH} more
          </Button>
        )}
      </div>
    )
  }
}

CampaignReviewList.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired
}

export default CampaignReviewList
