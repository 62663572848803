import React from "react"
import PropTypes from "prop-types"
import "styled-components/macro"

const RowWrapper = ({ removing, children }) => (
  <div
    css={`
      opacity: ${removing ? 0.1 : 1};
      transition: opacity 0.3s linear;
    `}>
    {children}
  </div>
)

RowWrapper.propTypes = {
  removing: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired
}

export default RowWrapper
