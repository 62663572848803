import { GET_TRACKING } from "@actions/tracking/"
import { get } from "@helpers/fetch"

/**
 * Fetches campaign tracking from the server
 *
 * @returns {Function} A redux dispatch
 */

export const getTracking = () => dispatch => dispatch(get(GET_TRACKING, "campaigns/tracking"))
