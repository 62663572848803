import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import VisibilitySensor from "react-visibility-sensor"
import "styled-components/macro"
import { Button } from "@bitcine/cs-theme"
import { updateCampaignFormModel } from "@api/campaign_form/"
import { deleteCampaign } from "@api/campaign/"

export class Campaign extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content_list_expanded: false
    }
    this.MAX_DCP_LIST_LENGTH = 5
  }
  render() {
    const { deleting, campaign, updateCampaignFormModel, deleteCampaign } = this.props
    return (
      <article>
        <VisibilitySensor minTopValue='20' partialVisibility>
          {({ isVisible }) => (
            <div
              css={`
                opacity: ${deleting ? 0.2 : isVisible ? 1 : 0};
                transition: opacity 0.5s linear;
              `}
              className='bg-white rounded box-shadow mb2'>
              <div className='flex items-center px3 py2 border-bottom border-gray-5'>
                <div className='flex-auto flex items-start'>
                  <div>
                    <div>Date Created</div>
                    <strong>{dayjs(campaign.created_at).format("MMM Do, YYYY")}</strong>
                  </div>
                  {campaign.submitted_at && (
                    <div className='pl3'>
                      <div>Date Submitted</div>
                      <strong>{dayjs(campaign.submitted_at).format("MMM Do, YYYY")}</strong>
                    </div>
                  )}
                </div>
                <div className='right-align flex items-center'>
                  <div className='pr3'>
                    <div>Start Date</div>
                    <strong>{dayjs(campaign.starts_at).format("MMM Do, YYYY")}</strong>
                  </div>
                  <div>
                    <div>End Date</div>
                    <strong>{dayjs(campaign.ends_at).format("MMM Do, YYYY")}</strong>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-between p3'>
                <div>
                  <div className='flex items-end'>
                    <h4>{campaign.name}</h4>
                  </div>
                  <div>{campaign.created_by && `Created by ${campaign.created_by}`}</div>
                  <div className='flex items-center'>
                    <span
                      css={`
                        border-radius: 50%;
                        width: 18px;
                        height: 18px;
                        font-size: 18px;
                        background: ${campaign.submitted_at ? "#1ACA8A" : "#353149"};
                      `}
                      className={`material-icons mr1 white flex items-center justify-center`}>
                      {campaign.submitted_at ? "check" : "remove"}
                    </span>
                    <strong
                      css={`
                        color: ${campaign.submitted_at ? "#1ACA8A" : "#353149"};
                      `}>
                      {campaign.submitted_at ? "Campaign submitted" : "Campaign not submitted"}
                      {campaign.submitted_by && ` by ${campaign.submitted_by}`}
                    </strong>
                  </div>
                </div>
                {!campaign.submitted_at && (
                  <div>
                    <Link to='/campaigns/form/details'>
                      <Button
                        css={`
                          width: 160px;
                        `}
                        onClick={() => updateCampaignFormModel({ _id: campaign._id })}
                        className='white small'>
                        Continue Campaign
                      </Button>
                    </Link>
                    <br/>
                    <Button
                      css={`
                        width: 160px;
                      `}
                      onClick={() => deleteCampaign(campaign._id)}
                      disabled={deleting}
                      className={`mt1 small outline ${deleting ? "pending" : ""}`}>
                      Delete Campaign
                    </Button>
                  </div>
                )}
                {campaign.submitted_at && (
                  <Link to={"/campaigns/" + campaign._id}>
                    <Button className='small white'>View Details</Button>
                  </Link>
                )}
              </div>
              <div className='px3 pb3'>
                <div className='mt3'>
                  <strong>Campaign Content:</strong>
                  {campaign.contents
                    .filter((dcp, index) => this.state.content_list_expanded || index < this.MAX_DCP_LIST_LENGTH)
                    .map(dcp => (
                      <div key={dcp._id}>{dcp.name}</div>
                    ))}
                  {campaign.contents.length > this.MAX_DCP_LIST_LENGTH && !this.state.content_list_expanded && (
                    <Button
                      css={`
                        height: auto;
                      `}
                      onClick={() => this.setState({ content_list_expanded: true })}
                      className='link underline inline-block regular'>
                      View {campaign.contents.length - this.MAX_DCP_LIST_LENGTH} more
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </VisibilitySensor>
      </article>
    )
  }
}

Campaign.propTypes = {
  campaign: PropTypes.shape({
    ends_at: PropTypes.string.isRequired,
    starts_at: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    submitted_at: PropTypes.string
  }).isRequired,
  deleting: PropTypes.bool.isRequired,
  updateCampaignFormModel: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  deleting: state.campaigns.deleting_campaign_id === ownProps.campaign._id
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormModel: bindActionCreators(updateCampaignFormModel, dispatch),
  deleteCampaign: bindActionCreators(deleteCampaign, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaign)
