import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { updateCampaignFormStep } from "@api/campaign_form/"
import { connect } from "react-redux"

const Header = ({ updateCampaignFormStep, list }) => (
  <div className='flex items-center justify-between'>
    <h4>All Projects</h4>
    {list.status === "READY" && (
      <input
        placeholder='Search for a project...'
        value={list.search_term}
        className='cs-input'
        onChange={e => {
          updateCampaignFormStep("content", { available_list: { ...list, search_term: e.target.value } })
        }}/>
    )}
  </div>
)

const mapStateToProps = state => ({
  list: state.campaign_form.steps.content.available_list
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch)
})

Header.propTypes = {
  updateCampaignFormStep: PropTypes.func.isRequired,
  list: PropTypes.shape({
    search_term: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
