import { ADD_GLOBAL_MESSAGE, REMOVE_GLOBAL_MESSAGE } from "@actions/global_messages/"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ADD_GLOBAL_MESSAGE:
      return [...state, action.payload]

    case REMOVE_GLOBAL_MESSAGE:
      return state.filter(msg => msg._id !== action._id)

    default:
      return state
  }
}
