import React, { useState } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import "styled-components/macro"
import { Status, Table, Modal, Button } from "@bitcine/cs-theme"
import useDidMount from "@rooks/use-did-mount"
import { connect } from "react-redux"
import { getTracking } from "@api/tracking/"
import CinemaName from "@src/components/table/cinema_name"
import CinemaDates from "@src/components/table/cinema_dates"
import CinemaStatus from "@src/components/table/cinema_status"

const Tracking = ({ tracking, getTracking }) => {
  const [search, updateSearch] = useState("")
  const [activeContentsView, updateActiveContentsView] = useState(null)
  useDidMount(() => getTracking())
  const filter = list => {
    const search_formatted = search.toLowerCase().trim()
    return list.filter(item => {
      const campaign_name = item.name || ""
      const cinema_name = item.cinema ? item.cinema.name || "" : ""
      return (
        campaign_name
          .toLowerCase()
          .trim()
          .includes(search_formatted) ||
        cinema_name
          .toLowerCase()
          .trim()
          .includes(search_formatted)
      )
    })
  }
  return (
    <Status pending={tracking.status === "PENDING"} error={tracking.status === "ERROR"}>
      <React.Fragment>
        <div className='flex items-center justify-between'>
          <h4>Consolidated Tracking</h4>
          <input
            placeholder='Search for a booking or cinema...'
            onChange={e => updateSearch(e.target.value)}
            className='cs-input'
            css={`
              min-width: 255px;
            `}
            value={search}/>
        </div>
        <Table
          className='my3'
          widths={["auto", "260", "200", "190", "160"]}
          header={{
            columns: [
              { text: "Destination" },
              { text: "Projects" },
              { text: "Campaign" },
              { text: "Date" },
              { text: "Status" }
            ]
          }}
          body={{
            data: filter(tracking.list),
            row: {
              render: [
                data => <CinemaName cinema={data.cinema}/>,
                data =>
                  !data.contents || !Array.isArray(data.contents) ? (
                    "N/A"
                  ) : (
                    <div className='flex items-center truncate'>
                      {data.contents
                        .filter((dcp, index) => index < 1)
                        .map((dcp, index) => `${index > 0 ? ", " : ""} ${dcp.name}`)}
                      &nbsp;
                      {data.contents.length > 1 && (
                        <Button
                          css={`
                            height: auto;
                          `}
                          onClick={() => updateActiveContentsView(data.contents)}
                          className='link'>
                          <small className='regular underline block'>+{data.contents.length - 1} More</small>
                        </Button>
                      )}
                    </div>
                  ),
                data => <div className='truncate'>{data.name}</div>,
                data => <CinemaDates cinema={data.cinema} model={data}/>,
                data => <CinemaStatus delivery={data.cinema.status} cinema={data.cinema}/>
              ]
            }
          }}/>
        <Modal title='Content' onClose={() => updateActiveContentsView(null)} open={activeContentsView !== null}>
          {activeContentsView && (
            <Table
              className=''
              widths={["auto"]}
              header={{
                columns: [{ text: "Project" }]
              }}
              body={{
                data: activeContentsView,
                row: {
                  render: [data => data.project.title]
                }
              }}/>
          )}
        </Modal>
      </React.Fragment>
    </Status>
  )
}

Tracking.propTypes = {
  tracking: PropTypes.shape({
    status: PropTypes.string.isRequired
  }).isRequired,
  getTracking: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  tracking: state.tracking
})

const mapDispatchToProps = dispatch => ({
  getTracking: bindActionCreators(getTracking, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tracking)
