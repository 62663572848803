import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import DayPicker from "react-day-picker/DayPickerInput"
import dayjs from "dayjs"
import "styled-components/macro"
import { Button } from "@bitcine/cs-theme"
import { overrideCinemaDate, resetCampaignDateOverride } from "@api/campaign_form/"
import getMinimumDay from "../../../../functions/minimum_day"
import getMaximumDay from "../../../../functions/maximum_day"

const Dates = ({ cinema, model, overrideCinemaDate, resetCampaignDateOverride }) => (
  <div className='col-4'>
    <div className='flex items-center'>
      <strong>Campaign date override</strong>
      {model.cinema_overrider_dates_map && model.cinema_overrider_dates_map[cinema._id] && (
        <Button
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            resetCampaignDateOverride(cinema)
          }}
          className='link ml2 regular muted underline'
          css={`
            height: auto;
            font-size: 12px;
          `}>
          Reset
        </Button>
      )}
    </div>
    <div className='flex items-center mt1'>
      {["starts_at", "ends_at"].map((type, index) => (
        <div
          key={type}
          css={`
            .DayPickerInput {
              width: 100%;
            }
          `}
          className='pr2'>
          <label className='capitalize' htmlFor={type}>
            {type.replace("s_at", "")} date
          </label>
          <DayPicker
            key={type}
            value={
              model.cinema_overrider_dates_map && model.cinema_overrider_dates_map[cinema._id]
                ? dayjs(model.cinema_overrider_dates_map[cinema._id][type]).toDate()
                : model[type]
                  ? dayjs(model[type]).toDate()
                  : ""
            }
            inputProps={{
              className: "cs-input col-12 block",
              id: type
            }}
            id={type}
            dayPickerProps={{
              disabledDays: {
                before: getMinimumDay(type, model.starts_at, model.ends_at),
                after: getMaximumDay(type, model.starts_at, model.ends_at)
              }
            }}
            placeholder=''
            formatDate={date => dayjs(date).format("MMM Do, YYYY")}
            onDayChange={date => overrideCinemaDate(cinema, type, dayjs(date).format("YYYY-MM-DD HH:mm:ss"))}/>
        </div>
      ))}
    </div>
  </div>
)

const mapStateToProps = state => ({
  model: state.campaign_form.model
})

const mapDispatchToProps = dispatch => ({
  overrideCinemaDate: bindActionCreators(overrideCinemaDate, dispatch),
  resetCampaignDateOverride: bindActionCreators(resetCampaignDateOverride, dispatch)
})

Dates.propTypes = {
  resetCampaignDateOverride: PropTypes.func.isRequired,
  overrideCinemaDate: PropTypes.func.isRequired,
  cinema: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired,
  model: PropTypes.shape({
    excluded_screen_ids: PropTypes.array,
    starts_at: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired,
    cinema_overrider_dates_map: PropTypes.object
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dates)
