import { GET_TRACKING_PENDING, GET_TRACKING_FAILED, GET_TRACKING_FULFILLED } from "@actions/tracking/index"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_TRACKING_PENDING:
      return INITIAL_STATE

    case GET_TRACKING_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_TRACKING_FULFILLED:
      let list = []
      action.payload.forEach(campaign => {
        campaign.cinemas.forEach(cinema => {
          list.push({
            _id: campaign._id + cinema._id,
            name: campaign.name,
            cinema_overrider_dates_map: campaign.cinema_overrider_dates_map,
            starts_at: campaign.starts_at,
            ends_at: campaign.ends_at,
            contents: campaign.contents,
            cinema: {
              ...cinema,
              status: campaign.cinema_meta ? campaign.cinema_meta[cinema._id] : null
            }
          })
        })
      })
      return {
        status: "READY",
        list
      }

    default:
      return state
  }
}
