import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "styled-components/macro"
import CAMPAIGN_FORM_STEPS from "@src/constants/campaign_form_steps"
import { parseCampaignFormCurrentStep } from "@api/campaign_form/"
import fadeIn from "@src/css/exports/fade_in"

const Steps = ({ step }) => {
  const { index } = parseCampaignFormCurrentStep(step)
  return (
    <div
      css={`
        ${fadeIn()};
        :after {
          content: "";
          display: block;
          height: 1px;
          border-top: 1px dashed #e0e3e4;
          width: 100%;
          position: absolute;
          left: 0;
          top: 50%;
        }
      `}
      className='flex items-center justify-between max-width-3 mx-auto relative'>
      {CAMPAIGN_FORM_STEPS.map((s, i) => (
        <Link
          key={i}
          onClick={e => {
            if (i >= index) {
              e.preventDefault()
            }
          }}
          to={`/campaigns/form/${s.step}`}
          className='bold flex items-center px2 relative z1'
          css={`
            color: ${i <= index ? "#545C63" : "#C7CDCF"};
            transition: all 0.3s linear;
            background: #f9f9f9;
            cursor: ${i >= index ? "default" : "pointer"};
          `}>
          <span
            css={`
              width: 16px;
              height: 16px;
              border-radius: 50%;
              transition: all 0.3s linear;
              background: ${i < index ? "#1EBF68" : ""};
              border: ${i > index ? "2px solid rgba(166,166,166,0.5)" : i === index ? "2px solid #1EBF68" : ""};
              font-size: 16px;
            `}
            className='inline-block mr1 material-icons white bold'>
            {index > i && "check"}
          </span>
          {s.text}
        </Link>
      ))}
    </div>
  )
}

Steps.propTypes = {
  step: PropTypes.string.isRequired
}
export default Steps
