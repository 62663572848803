import React from "react"
import PropTypes from "prop-types"
import "styled-components/macro"
import { parseCampaignFormCurrentStep } from "@api/campaign_form/"
import fadeIn from "@src/css/exports/fade_in"

export const Header = ({ step }) => {
  const { active } = parseCampaignFormCurrentStep(step)
  return (
    <div
      css={`
        ${fadeIn()};
      `}
      className='center max-width-2 mx-auto py3'>
      <h2>{active.title}</h2>
      <p>{active.description}</p>
    </div>
  )
}

Header.propTypes = {
  step: PropTypes.string.isRequired
}
export default Header
