import React from "react"
import { Link } from "react-router-dom"
import "styled-components/macro"
import { Button } from "@bitcine/cs-theme"
import { ReactComponent as Icon } from "@src/media/icons/media.inline.svg"

const CampaignSuccess = () => (
  <section className='center py3'>
    <Icon
      css={`
        width: 200px;
        margin-bottom: -4em;
      `}/>
    <h2 className='mt0'>Campaign Launched</h2>
    <p className='mb2'>Congratulations, your new campaign has been launched!</p>
    <Link to='/campaigns'>
      <Button className='white small'>View All Campaigns</Button>
    </Link>
  </section>
)

export default CampaignSuccess
