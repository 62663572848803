import React from "react"
import { Switch, Route } from "react-router-dom"
import CampaignForm from "./form"
import CampaignList from "./list"
import CampaignSubmitted from "./form_submitted"
import Campaign from "./campaign"

const Campaigns = () => (
  <Switch>
    <Route exact path='/campaigns' component={CampaignList}/>
    <Route path='/campaigns/form' component={CampaignForm}/>
    <Route path='/campaigns/submitted' component={CampaignSubmitted}/>
    <Route path='/campaigns/:campaignID' component={Campaign}/>
  </Switch>
)

Campaigns.propTypes = {}

export default Campaigns
