import { DELETE_CAMPAIGN, GET_CAMPAIGN, PUSHER_CAMPAIGN_CINEMA_UPDATED_EVENT } from "@actions/campaign/"
import { del, get } from "@helpers/fetch"
import { addGlobalMessage } from "@api/global_messages/"

/**
 * Deletes a campaign on the server
 * The response will remove the campaign from state.campaigns.list
 *
 * @param {String} [_id]
 *
 * @returns {Function} A redux dispatch
 */

export const deleteCampaign = _id => dispatch => {
  if (window.confirm("Are you sure you want to delete this campaign?")) {
    dispatch(
      del(
        DELETE_CAMPAIGN,
        `campaigns/${_id}`,
        { _id },
        () => dispatch(addGlobalMessage("Campaign deleted!", "success")),
        () => dispatch(addGlobalMessage("An error occurred deleting this campaign!", "error"))
      )
    )
  }
}

/**
 * Fetches a campaign on the server
 *
 * @param {String} [_id]
 *
 * @returns {Function} A redux dispatch
 */

export const getCampaign = _id => dispatch => dispatch(get(GET_CAMPAIGN, `campaigns/${_id}`))

/**
 * Called after a pusher event
 *
 * @param {Object} [payload]
 *
 * @returns {Function} A redux dispatch
 */

export const onPusherCampaignCinemaUpdatedEvent = payload => dispatch =>
  dispatch({ type: PUSHER_CAMPAIGN_CINEMA_UPDATED_EVENT, payload })
