import {
  GET_NEW_CAMPAIGN_PRICE_PENDING,
  GET_NEW_CAMPAIGN_PRICE_FAILED,
  GET_NEW_CAMPAIGN_PRICE_FULFILLED
} from "@actions/campaign_form/"
import INITIAL_STATE from "../config"

export default function reducer(state = INITIAL_STATE.price, action = {}) {
  switch (action.type) {
    case GET_NEW_CAMPAIGN_PRICE_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_NEW_CAMPAIGN_PRICE_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_NEW_CAMPAIGN_PRICE_FULFILLED:
      return {
        ...state,
        status: "READY",
        ...action.payload
      }

    default:
      return state
  }
}
