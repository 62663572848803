import {
  POST_CAMPAIGN_FORM,
  PUT_CAMPAIGN_FORM,
  GET_CAMPAIGN_FORM_AVAILABLE_CONTENT,
  POST_CONTENT_TO_CAMPAIGN_FORM,
  RESET_CAMPAIGN_FORM,
  GET_CAMPAIGN_FORM_CONTENT,
  GET_CAMPAIGN_FORM,
  UPDATE_CAMPAIGN_FORM_MODEL,
  UPDATE_CAMPAIGN_FORM_STEP,
  REMOVE_CONTENT_FROM_CAMPAIGN,
  POST_NEW_CAMPAIGN_TEMPLATE,
  RESET_NEW_CAMPAIGN_NEW_TEMPLATE,
  RESET_NEW_CAMPAIGN_LOAD_TEMPLATE,
  GET_NEW_CAMPAIGN_TEMPLATES,
  LOAD_TEMPLATES_TO_NEW_CAMPAIGN,
  GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS,
  ADD_CINEMAS_TO_NEW_CAMPAIGN,
  REMOVE_CINEMA_FROM_NEW_CAMPAIGN,
  UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS,
  RESET_NEW_CAMPAIGN_STEP,
  UPDATE_CINEMA_OVERRIDE,
  SUBMIT_NEW_CAMPAIGN,
  GET_NEW_CAMPAIGN_PRICE,
  UPDATE_CAMPAIGN_FORM_CINEMA_SEARCH
} from "@actions/campaign_form/"
import CAMPAIGN_FORM_STEPS from "@src/constants/campaign_form_steps"
import { post, put, get } from "@helpers/fetch"
import { addGlobalMessage } from "@api/global_messages/"

/**
 * Fetches a campaign to use when editing
 *
 * @returns {Function} A redux dispatch
 */

export const getCampaignForm = () => (dispatch, getState) =>
  dispatch(get(GET_CAMPAIGN_FORM, `campaigns/${getState().campaign_form.model._id}`))

/**
 * Updates the new campaign state in redux by step
 * This is a helper to update deep state without accessing it
 * in a component.
 *
 * @param {String} [step] The step to update in redux
 *
 * @param {Object} [payload] This will be the object passed
 * through from the to update. Any keys that currently
 * exists on state.campaigns will be overriden.
 *
 * @returns {Function} A redux dispatch
 */

export const updateCampaignFormStep = (step, payload) => dispatch =>
  dispatch({ type: UPDATE_CAMPAIGN_FORM_STEP, step, payload })

/**
 * Updates the new/editing campaign model state in redux
 * These changes are only client-side, and will note post
 * to the server.
 *
 * @param {Object} [payload] This will be the object passed
 * through from the to update. Any keys that currently
 * exists on state.campaign_form.odel will be overriden.
 *
 * @returns {Function} A redux dispatch
 */

export const updateCampaignFormModel = payload => dispatch => dispatch({ type: UPDATE_CAMPAIGN_FORM_MODEL, payload })

/**
 * Sets the state after clicking next inside of
 * the new campaign form.
 *
 * Form validation is handled here to ensure all fields
 * are filled in properly.

 * Requests are also handled here to update the new
 * campaign on the server.
 *
 * For new campaigns, the campaign is created after the first step ('details').
 *
 * @returns {Function} One or many redux dispatches
 */

export const nextCampaignFormStep = () => (dispatch, getState) => {
  const step = window.location.pathname.split("/").pop()
  switch (step) {
    case "details":
      dispatch(onDetailsStepComplete())
      break
    case "content":
      dispatch(onContentStepComplete())
      break
    case "cinemas":
      dispatch(onCinemasStepComplete())
      break
    case "review":
      dispatch(onReviewStepComplete())
      break
    default:
  }
}

/**
 * Called after the user completes the 'details section'. It:
 *
 * 1. Validates the form
 * 2. Posts/Puts the campaign (put is used if the campaign is already created)
 *
 * The two outcomes possible are:
 * 1. The form is not filled out and error UI is shown.
 * 2. The form is filled out so it posts to the server and
 * proceeds to the next step.
 *
 * @returns {Function} One or many redux dispatches
 */

const onDetailsStepComplete = () => (dispatch, getState) => {
  const history = getState().utilities.history
  const campaign = getState().campaign_form
  const { next } = parseCampaignFormCurrentStep("details")
  const errors = ["name", "starts_at", "ends_at"].filter(key => !campaign.model[key])
  const payload = {
    name: campaign.model.name,
    starts_at: campaign.model.starts_at,
    ends_at: campaign.model.ends_at
  }
  dispatch(updateCampaignFormStep("details", { validation_errors: errors }))
  if (!errors.length) {
    // If there is a model._id, that means we are continuing an existing campaign
    // No creation is necessary here, instead we can update the database entry
    if (campaign.model._id) {
      dispatch(
        put(PUT_CAMPAIGN_FORM, `campaigns/${campaign.model._id}`, payload, () => {
          history.push(`/campaigns/form/${next.step}`)
        })
      )
    }
    // If model._id is not set, we should create an entry in the database
    else {
      dispatch(
        post(POST_CAMPAIGN_FORM, "campaigns", payload, () => {
          history.push(`/campaigns/form/${next.step}`)
        })
      )
    }
  }
}

/**
 * Called after the user completes the 'content section'. It:
 *
 * 1. Ensures there is at least one piece of content for the campaign
 * 2. Navigates to the 'cinemas' page
 *
 *
 * @returns {Function} One or many redux dispatches
 */

const onContentStepComplete = () => (dispatch, getState) => {
  const history = getState().utilities.history
  const { next } = parseCampaignFormCurrentStep("content")
  const list = getState().campaign_form.model.contents || []
  if (!list.length) {
    dispatch(addGlobalMessage("Campaigns must include content!", "error"))
    dispatch(updateCampaignFormStep("content", { show_add_content_helper_tooltip: true }))
  } else {
    history.push(`/campaigns/form/${next.step}`)
  }
}

/**
 * Called after the user completes the 'cinemas section'. It:
 *
 * 1. Ensures there is at least one cinema for delivery
 * 2. Navigates to the 'review' page
 *
 *
 * @returns {Function} One or many redux dispatches
 */

const onCinemasStepComplete = () => (dispatch, getState) => {
  const history = getState().utilities.history
  const { next } = parseCampaignFormCurrentStep("cinemas")
  const list = getState().campaign_form.model.cinemas || []
  if (!list.length) {
    dispatch(addGlobalMessage("Campaigns must include cinemas!", "error"))
    dispatch(updateCampaignFormStep("cinemas", { show_add_cinemas_helper_tooltip: true }))
  } else {
    history.push(`/campaigns/form/${next.step}`)
  }
}

/**
 * Called after the user completes the 'revies section'. It:
 *
 * 1. Ensures there is at least campaign title is set
 * 2.
 *
 *
 * @returns {Function} One or many redux dispatches
 */

const onReviewStepComplete = () => (dispatch, getState) => {
  const campaign = getState().campaign_form
  const billing = getState().billing
  const errors = [
    ...["name", "starts_at", "ends_at"].filter(key => !campaign.model[key]),
    ...["active_card_id"].filter(key => !billing[key] && !billing.use_post_pay)
  ]
  dispatch(updateCampaignFormStep("review", { validation_errors: errors }))
  if (!errors.length) {
    dispatch(
      post(
        SUBMIT_NEW_CAMPAIGN,
        `campaigns/${campaign.model._id}/submit`,
        {
          credit_card_id: billing.active_card_id,
          use_post_pay: billing.use_post_pay
        },
        () => getState().utilities.history.push("/campaigns/submitted")
      )
    )
  }
}

/**
 * Parses out usefull information about the current new campaign progress
 *
 * @returns {Function} One or many redux dispatches
 */

export const parseCampaignFormCurrentStep = step => {
  const index = CAMPAIGN_FORM_STEPS.indexOf(CAMPAIGN_FORM_STEPS.find(s => s.step === step))
  const active = CAMPAIGN_FORM_STEPS[index] || {}
  const previous = CAMPAIGN_FORM_STEPS[index - 1] || {}
  const next = CAMPAIGN_FORM_STEPS[index + 1] || {}
  return { index, active, previous, next }
}

/**
 * Fetches a list of available DCPs for a new campaign.
 * This list is comprised of all of the DCPs in a user/org account
 * in CineSend.
 *
 * @returns {Function} A redux dispatch
 */

export const getCampaignFormAvailableContent = () => dispatch =>
  dispatch(get(GET_CAMPAIGN_FORM_AVAILABLE_CONTENT, "campaigns/available_content"))

/**
 * Fetches a list of available DCPs for a new campaign.
 * This list is comprised of all of the DCPs in a user/org account
 * in CineSend.
 *
 * @returns {Function} A redux dispatch
 */

export const getCampaignContent = () => (dispatch, getState) =>
  dispatch(get(GET_CAMPAIGN_FORM_CONTENT, `campaigns/${getState().campaign_form.model._id}/contents`))

/**
 * Adds content (DCPs) to a new campaign
 *
 * @returns {Function} A redux dispatch
 */

export const addContentToCampaignForm = () => (dispatch, getState) =>
  dispatch(
    put(POST_CONTENT_TO_CAMPAIGN_FORM, `campaigns/${getState().campaign_form.model._id}/content`, {
      dcpIds: [
        ...getState().campaign_form.steps.content.available_list.selected_content_ids,
        ...getState().campaign_form.model.contents.map(({ _id }) => _id)
      ]
    })
  )

/**
 * Removes a dcp/file from a campaign.
 *
 * @param {String} [id] The id of the model to remove
 *
 * @returns {Function} A redux dispatch
 */

export const removeContentFromCampaign = id => (dispatch, getState) => {
  if (window.confirm("Are you sure you want to remove this content from the campaign?")) {
    dispatch(
      put(
        REMOVE_CONTENT_FROM_CAMPAIGN,
        `campaigns/${getState().campaign_form.model._id}/content`,
        {
          dcpIds: getState()
            .campaign_form.model.contents.filter(({ _id }) => _id !== id)
            .map(file => file._id),
          __REDUX_deleting_content_ids: [id]
        },
        () => dispatch(addGlobalMessage("Content removed from campaign!", "success")),
        () => dispatch(addGlobalMessage("An error occurred removing this content", "error"))
      )
    )
  }
}

/**
 * Removes dcps/files from a campaign.
 *
 * @returns {Function} A redux dispatch
 */

export const removeContentsFromCampaign = () => (dispatch, getState) => {
  if (window.confirm("Are you sure you want to remove these DCPs from the campaign?")) {
    const ids_to_remove = getState().campaign_form.steps.content.selected_content_ids
    dispatch(
      put(
        REMOVE_CONTENT_FROM_CAMPAIGN,
        `campaigns/${getState().campaign_form.model._id}/content`,
        {
          dcpIds: getState()
            .campaign_form.model.contents.filter(({ _id }) => !ids_to_remove.includes(_id))
            .map(file => file._id),
          __REDUX_deleting_content_ids: ids_to_remove
        },
        () => dispatch(addGlobalMessage(`DCP${ids_to_remove.length > 1 ? "s" : ""} removed from campaign!`, "success")),
        () =>
          dispatch(
            addGlobalMessage(`An error occurred removing these DCP{ids_to_remove.length > 1 ? 's' : ''}`, "error")
          )
      )
    )
  }
}

/**
 * Resets the campaign form back to its original blank state.
 * Useful when navigating away from the campaign page.
 *
 * @returns {Function} A redux dispatch
 */

export const resetCampaignForm = () => dispatch => dispatch({ type: RESET_CAMPAIGN_FORM })

/**
 * Posts a new template to the server
 *
 * @returns {Function} A redux dispatch
 */

export const postNewTemplate = () => (dispatch, getState) =>
  dispatch(
    post(
      POST_NEW_CAMPAIGN_TEMPLATE,
      `templates/`,
      {
        name: getState().campaign_form.steps.cinemas.new_template.name,
        cinemaIds: getState().campaign_form.model.cinemas.map(({ _id }) => _id)
      },
      () => dispatch(addGlobalMessage("Template saved!", "success")),
      () => dispatch(addGlobalMessage("An error occurred saving this template", "error"))
    )
  )

/**
 * Resets the campaign_form.new_template to its initial state
 *
 * @returns {Function} A redux dispatch
 */

export const resetNewCampaignNewTemplate = () => dispatch => dispatch({ type: RESET_NEW_CAMPAIGN_NEW_TEMPLATE })

/**
 * Resets the campaign_form.saved_templates to its initial state
 *
 * @returns {Function} A redux dispatch
 */

export const resetNewCampaignLoadTemplate = () => dispatch => dispatch({ type: RESET_NEW_CAMPAIGN_LOAD_TEMPLATE })

/**
 * Resets the campaign_form.saved_templates to its initial state
 *
 * @param {String} [step]
 *
 * @returns {Function} A redux dispatch
 */

export const resetNewCampaignFormStep = step => dispatch => dispatch({ type: RESET_NEW_CAMPAIGN_STEP, step })

/**
 * Fetches a list of available templates to apply to a new campaign.
 *
 * @returns {Function} A redux dispatch
 */

export const getTemplates = () => dispatch => dispatch(get(GET_NEW_CAMPAIGN_TEMPLATES, "templates"))

/**
 * Posts an array of tempaltes to a new campaign
 *
 * @returns {Function} A redux dispatch
 */

export const applySavedTemplateToNewCampaign = () => (dispatch, getState) => {
  const _ids = getState().campaign_form.steps.cinemas.saved_templates.selected_template_ids
  dispatch(
    put(
      LOAD_TEMPLATES_TO_NEW_CAMPAIGN,
      `campaigns/${getState().campaign_form.model._id}/templates`,
      {
        templateIds: _ids
      },
      () => dispatch(addGlobalMessage(`Template${_ids.length > 1 ? "s" : ""} applied!`, "success")),
      () =>
        dispatch(
          addGlobalMessage(
            `An error occurred applying ${_ids.length > 1 ? "these" : "this"} template${_ids.length > 1 ? "s" : ""}`,
            "error"
          )
        )
    )
  )
}

/**
 * Searches the server for a list of cinemas
 * Note: This function should be debounced.
 * The debouncing should be handled inside of a react <Component/>
 *
 * @returns {Function} A redux dispatch
 */

export const onCinemaSearch = e => (dispatch, getState) => {
  const { search_term, take } = getState().campaign_form.steps.cinemas.available_list
  if (search_term.length > 2) {
    dispatch(get(GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS, `cinemas?search=${search_term}&take=${take}`))
  } else {
    dispatch(
      updateCampaignFormStep("cinemas", {
        available_list: {
          ...getState().campaign_form.steps.cinemas.available_list,
          status: "READY",
          list: []
        }
      })
    )
  }
}

/**
 * Adds an array of cinemas onto a campaign
 *
 * @returns {Function} A redux dispatch
 */

export const addCinemasToNewCampaign = () => (dispatch, getState) =>
  dispatch(
    put(ADD_CINEMAS_TO_NEW_CAMPAIGN, `campaigns/${getState().campaign_form.model._id}/cinemas`, {
      cinemaIds: [
        ...getState().campaign_form.steps.cinemas.available_list.selected_cinema_ids,
        ...getState().campaign_form.model.cinemas.map(({ _id }) => _id)
      ]
    })
  )

/**
 * Removes a cinema from a campaign
 *
 * @param {String} [id] The id of the cinema to remove
 *
 * @returns {Function} A redux dispatch
 */

export const removeCinemaFromNewCampaign = id => (dispatch, getState) => {
  if (window.confirm("Are you sure you want to remove this cinema from the campaign?")) {
    dispatch(
      put(
        REMOVE_CINEMA_FROM_NEW_CAMPAIGN,
        `campaigns/${getState().campaign_form.model._id}/cinemas`,
        {
          cinemaIds: getState()
            .campaign_form.model.cinemas.filter(({ _id }) => _id !== id)
            .map(({ _id }) => _id),
          __REDUX_deleting_cinema_ids: [id]
        },
        () => dispatch(addGlobalMessage("Cinema removed!", "success"))
      )
    )
  }
}

/**
 * Removes an array of cinemas from a campaign
 *
 * @returns {Function} A redux dispatch
 */

export const removeCinemasFromNewCampaign = () => (dispatch, getState) => {
  if (window.confirm("Are you sure you want to remove these cinemas from the campaign?")) {
    const ids_to_remove = getState().campaign_form.steps.cinemas.selected_cinema_ids
    dispatch(
      put(
        REMOVE_CINEMA_FROM_NEW_CAMPAIGN,
        `campaigns/${getState().campaign_form.model._id}/cinemas`,
        {
          cinemaIds: getState()
            .campaign_form.model.cinemas.filter(({ _id }) => !ids_to_remove.includes(_id))
            .map(({ _id }) => _id),
          __REDUX_deleting_cinema_ids: ids_to_remove
        },
        () => dispatch(addGlobalMessage("Cinemas removed!", "success"))
      )
    )
  }
}

/**
 * Removes an array of cinemas from a campaign
 *
 * @returns {Function} A redux dispatch
 */

export const updateNewCampaignCinemaScreens = id => (dispatch, getState) => {
  const ids = getState().campaign_form.model.excluded_screen_ids || []
  dispatch(
    put(UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS, `campaigns/${getState().campaign_form.model._id}/screens`, {
      screenIds: ids.includes(id) ? ids.filter(_id => _id !== id) : [...ids, id],
      __REDUX_updating_screen_id: id
    })
  )
}

/**
 * Overrides a cinemas starts_at/ends_at property
 * By default, all cinemas use the campaign.starts_at and
 * campaign.ends_at as their dates. Each cinema can be
 * overriden to have their own dates.
 *
 * @param {Object} [cinema]
 *
 * @param {String} [type] either starts_at or ends_at
 *
 * @param {String} [date]
 *
 * @returns {Function} A redux dispatch
 */

export const overrideCinemaDate = (cinema, type, date) => (dispatch, getState) => {
  const model = getState().campaign_form.model
  let dates = model.cinema_overrider_dates_map || {}
  if (dates[cinema._id]) {
    dates[cinema._id] = {
      ...dates[cinema._id],
      [type]: date
    }
  } else {
    dates[cinema._id] = {
      starts_at: model.starts_at,
      ends_at: model.ends_at,
      [type]: date
    }
  }
  dispatch(
    updateCampaignFormModel({
      cinema_overrider_dates_map: dates
    })
  )
  dispatch(saveCinemaOverride(cinema._id, dates[cinema._id]))
}

/**
 * Updates the campaign form cinema adding search
 *
 * @param {String} [value]
 *
 * @returns {Function} A redux dispatch
 */

export const updateCampaignFormCinemaSearch = value => dispatch =>
  dispatch({ type: UPDATE_CAMPAIGN_FORM_CINEMA_SEARCH, value })

/**
 * Removes a cinema from the campaign overriden dates
 *
 * @param {Object} [cinema]
 *
 * @returns {Function} A redux dispatch
 */

export const resetCampaignDateOverride = cinema => (dispatch, getState) => {
  let dates = getState().campaign_form.model.cinema_overrider_dates_map || {}
  if (dates[cinema._id]) {
    delete dates[cinema._id]
    dispatch(
      updateCampaignFormModel({
        cinema_overrider_dates_map: dates
      })
    )
    dispatch(saveCinemaOverride(cinema._id, null))
  }
}

/**
 * Saves a cinema override to the server
 *
 * @param {String} [_id]
 *
 * @param {object} [dates]
 *
 * @returns {Function} A redux dispatch
 */

const saveCinemaOverride = (_id, dates) => (dispatch, getState) =>
  dispatch(
    put(UPDATE_CINEMA_OVERRIDE, `campaigns/${getState().campaign_form.model._id}/cinemas/${_id}`, {
      dates
    })
  )

/**
 * Fetches the price of a new campaign from the server
 *
 * @returns {Function} A redux dispatch
 */

export const getCampaignPrice = () => (dispatch, getState) =>
  dispatch(get(GET_NEW_CAMPAIGN_PRICE, `campaigns/${getState().campaign_form.model._id}/cost`))
