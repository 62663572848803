export const UPDATE_CAMPAIGN_FORM_MODEL = "UPDATE_CAMPAIGN_FORM_MODEL"
export const UPDATE_CAMPAIGN_FORM_STEP = "UPDATE_CAMPAIGN_FORM_STEP"

export const GET_CAMPAIGN_FORM = "GET_CAMPAIGN_FORM"
export const GET_CAMPAIGN_FORM_PENDING = "GET_CAMPAIGN_FORM_PENDING"
export const GET_CAMPAIGN_FORM_FAILED = "GET_CAMPAIGN_FORM_FAILED"
export const GET_CAMPAIGN_FORM_FULFILLED = "GET_CAMPAIGN_FORM_FULFILLED"

export const POST_CAMPAIGN_FORM = "POST_CAMPAIGN_FORM"
export const POST_CAMPAIGN_FORM_PENDING = "POST_CAMPAIGN_FORM_PENDING"
export const POST_CAMPAIGN_FORM_FULFILLED = "POST_CAMPAIGN_FORM_FULFILLED"
export const POST_CAMPAIGN_FORM_FAILED = "POST_CAMPAIGN_FORM_FAILED"

export const PUT_CAMPAIGN_FORM = "PUT_CAMPAIGN_FORM"
export const PUT_CAMPAIGN_FORM_PENDING = "PUT_CAMPAIGN_FORM_PENDING"
export const PUT_CAMPAIGN_FORM_FULFILLED = "PUT_CAMPAIGN_FORM_FULFILLED"
export const PUT_CAMPAIGN_FORM_FAILED = "PUT_CAMPAIGN_FORM_FAILED"

export const GET_CAMPAIGN_FORM_AVAILABLE_CONTENT = "GET_CAMPAIGN_FORM_AVAILABLE_CONTENT"
export const GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_PENDING = "GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_PENDING"
export const GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_FULFILLED = "GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_FULFILLED"
export const GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_FAILED = "GET_CAMPAIGN_FORM_AVAILABLE_CONTENT_FAILED"

export const POST_CONTENT_TO_CAMPAIGN_FORM = "POST_CONTENT_TO_CAMPAIGN_FORM"
export const POST_CONTENT_TO_CAMPAIGN_FORM_PENDING = "POST_CONTENT_TO_CAMPAIGN_FORM_PENDING"
export const POST_CONTENT_TO_CAMPAIGN_FORM_FULFILLED = "POST_CONTENT_TO_CAMPAIGN_FORM_FULFILLED"
export const POST_CONTENT_TO_CAMPAIGN_FORM_FAILED = "POST_CONTENT_TO_CAMPAIGN_FORM_FAILED"

export const REMOVE_CONTENT_FROM_CAMPAIGN = "REMOVE_CONTENT_FROM_CAMPAIGN"
export const REMOVE_CONTENT_FROM_CAMPAIGN_PENDING = "REMOVE_CONTENT_FROM_CAMPAIGN_PENDING"
export const REMOVE_CONTENT_FROM_CAMPAIGN_FULFILLED = "REMOVE_CONTENT_FROM_CAMPAIGN_FULFILLED"
export const REMOVE_CONTENT_FROM_CAMPAIGN_FAILED = "REMOVE_CONTENT_FROM_CAMPAIGN_FAILED"

export const POST_NEW_CAMPAIGN_TEMPLATE = "POST_NEW_CAMPAIGN_TEMPLATE"
export const POST_NEW_CAMPAIGN_TEMPLATE_PENDING = "POST_NEW_CAMPAIGN_TEMPLATE_PENDING"
export const POST_NEW_CAMPAIGN_TEMPLATE_FULFILLED = "POST_NEW_CAMPAIGN_TEMPLATE_FULFILLED"
export const POST_NEW_CAMPAIGN_TEMPLATE_FAILED = "POST_NEW_CAMPAIGN_TEMPLATE_FAILED"

export const GET_NEW_CAMPAIGN_TEMPLATES = "GET_NEW_CAMPAIGN_TEMPLATES"
export const GET_NEW_CAMPAIGN_TEMPLATES_PENDING = "GET_NEW_CAMPAIGN_TEMPLATES_PENDING"
export const GET_NEW_CAMPAIGN_TEMPLATES_FULFILLED = "GET_NEW_CAMPAIGN_TEMPLATES_FULFILLED"
export const GET_NEW_CAMPAIGN_TEMPLATES_FAILED = "GET_NEW_CAMPAIGN_TEMPLATES_FAILED"

export const LOAD_TEMPLATES_TO_NEW_CAMPAIGN = "LOAD_TEMPLATES_TO_NEW_CAMPAIGN"
export const LOAD_TEMPLATES_TO_NEW_CAMPAIGN_PENDING = "LOAD_TEMPLATES_TO_NEW_CAMPAIGN_PENDING"
export const LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FULFILLED = "LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FULFILLED"
export const LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FAILED = "LOAD_TEMPLATES_TO_NEW_CAMPAIGN_FAILED"

export const GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS = "GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS"
export const GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_PENDING = "GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_PENDING"
export const GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_FAILED = "GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_FAILED"
export const GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_FULFILLED = "GET_NEW_CAMPAIGN_AVAILABLE_CINEMAS_FULFILLED"

export const ADD_CINEMAS_TO_NEW_CAMPAIGN = "ADD_CINEMAS_TO_NEW_CAMPAIGN"
export const ADD_CINEMAS_TO_NEW_CAMPAIGN_PENDING = "ADD_CINEMAS_TO_NEW_CAMPAIGN_PENDING"
export const ADD_CINEMAS_TO_NEW_CAMPAIGN_FAILED = "ADD_CINEMAS_TO_NEW_CAMPAIGN_FAILED"
export const ADD_CINEMAS_TO_NEW_CAMPAIGN_FULFILLED = "ADD_CINEMAS_TO_NEW_CAMPAIGN_FULFILLED"

export const REMOVE_CINEMA_FROM_NEW_CAMPAIGN = "REMOVE_CINEMA_FROM_NEW_CAMPAIGN"
export const REMOVE_CINEMA_FROM_NEW_CAMPAIGN_PENDING = "REMOVE_CINEMA_FROM_NEW_CAMPAIGN_PENDING"
export const REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FAILED = "REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FAILED"
export const REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FULFILLED = "REMOVE_CINEMA_FROM_NEW_CAMPAIGN_FULFILLED"

export const UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS = "UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS"
export const UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_PENDING = "UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_PENDING"
export const UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FAILED = "UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FAILED"
export const UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FULFILLED = "UPDATE_NEW_CAMPAIGN_CINEMA_SCREENS_FULFILLED"

export const UPDATE_CAMPAIGN_FORM_CINEMA_SEARCH = "UPDATE_CAMPAIGN_FORM_CINEMA_SEARCH"
export const GET_CAMPAIGN_FORM_CONTENT = "GET_CAMPAIGN_FORM_CONTENT"

export const RESET_NEW_CAMPAIGN_LOAD_TEMPLATE = "RESET_NEW_CAMPAIGN_LOAD_TEMPLATE"
export const RESET_CAMPAIGN_FORM = "RESET_CAMPAIGN_FORM"
export const RESET_NEW_CAMPAIGN_NEW_TEMPLATE = "RESET_NEW_CAMPAIGN_NEW_TEMPLATE"
export const RESET_NEW_CAMPAIGN_STEP = "RESET_NEW_CAMPAIGN_STEP"

export const SUBMIT_NEW_CAMPAIGN = "SUBMIT_NEW_CAMPAIGN"
export const SUBMIT_NEW_CAMPAIGN_PENDING = "SUBMIT_NEW_CAMPAIGN_PENDING"
export const SUBMIT_NEW_CAMPAIGN_FAILED = "SUBMIT_NEW_CAMPAIGN_FAILED"
export const SUBMIT_NEW_CAMPAIGN_FULFILLED = "SUBMIT_NEW_CAMPAIGN_FULFILLED"

export const GET_NEW_CAMPAIGN_PRICE = "GET_NEW_CAMPAIGN_PRICE"
export const GET_NEW_CAMPAIGN_PRICE_PENDING = "GET_NEW_CAMPAIGN_PRICE_PENDING"
export const GET_NEW_CAMPAIGN_PRICE_FAILED = "GET_NEW_CAMPAIGN_PRICE_FAILED"
export const GET_NEW_CAMPAIGN_PRICE_FULFILLED = "GET_NEW_CAMPAIGN_PRICE_FULFILLED"

export const UPDATE_CINEMA_OVERRIDE = "UPDATE_CINEMA_OVERRIDE"
