import {
  GET_BILLING_PENDING,
  GET_BILLING_FAILED,
  GET_BILLING_FULFILLED,
  UPDATE_BILLING,
  ADD_PAYMENT_METHOD_PENDING,
  ADD_PAYMENT_METHOD_FULFILLED,
  ADD_PAYMENT_METHOD_FAILED
} from "@actions/billing/index"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_BILLING:
      return {
        ...state,
        ...action.payload
      }

    case GET_BILLING_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_BILLING_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_BILLING_FULFILLED:
      return {
        ...state,
        status: "FULFILLED",
        credit_cards: action.payload
      }

    case ADD_PAYMENT_METHOD_PENDING:
      return {
        ...state,
        new_payment_method_error_message: "",
        new_payment_method_status: "PENDING"
      }

    case ADD_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        new_payment_method_status: "READY",
        new_payment_method_error_message: action.error.message
      }

    case ADD_PAYMENT_METHOD_FULFILLED:
      return {
        ...state,
        show_new_payment_method: false,
        new_payment_method_status: "READY",
        new_payment_method_error_message: "",
        credit_cards: action.payload,
        active_card_id: action.payload[action.payload.length - 1]._id
      }

    default:
      return state
  }
}
