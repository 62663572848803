import React from "react"
import { ReactComponent as Icon } from "@src/media/icons/empty.inline.svg"
import { Empty } from "@bitcine/cs-theme"
import { Link } from "react-router-dom"

const PageNotFound = () => (
  <Empty
    icon={<Icon/>}
    title='404 Error'
    text={
      <span>
        This page was not found. Try checking&nbsp;
        <Link className='underline' to='/'>
          your campaigns.
        </Link>
      </span>
    }/>
)

export default PageNotFound
