import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { nextCampaignFormStep, parseCampaignFormCurrentStep } from "@api/campaign_form/"
import { Link } from "react-router-dom"
import { Button } from "@bitcine/cs-theme"
import fadeIn from "@src/css/exports/fade_in"

export const Footer = ({ step, nextCampaignFormStep, navigationDisabled }) => {
  const { index, active, previous } = parseCampaignFormCurrentStep(step)
  return (
    <nav
      css={`
        ${fadeIn()};
        box-shadow: 0 -1px 8px 0 rgba(146, 153, 159, 0.21);
        height: 64px;
        z-index: 10;
      `}
      className='fixed bottom-0 left-0 right-0 bg-white px3 flex items-center justify-between'>
      <Link to={index === 0 ? "/campaigns" : `/campaigns/form/${previous.step}`}>
        <Button
          css={`
            width: 100px;
          `}
          disabled={navigationDisabled}
          className='white'>
          {active.previous_button_text}
        </Button>
      </Link>
      <Button
        onClick={nextCampaignFormStep}
        css={`
          width: 150px;
        `}
        className={navigationDisabled ? "pending" : ""}
        disabled={navigationDisabled}>
        {active.next_button_text}
      </Button>
    </nav>
  )
}

Footer.propTypes = {
  step: PropTypes.string.isRequired,
  nextCampaignFormStep: PropTypes.func.isRequired,
  navigationDisabled: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  navigationDisabled: state.campaign_form.utils.disable_navigation
})

const mapDispatchToProps = dispatch => ({
  nextCampaignFormStep: bindActionCreators(nextCampaignFormStep, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
