import dayjs from "dayjs"
/**
 * Filters the campaign list
 *
 * @param {Array} [list] The list of campaigns
 * @param {String} [search]
 * @param {String} [sortBy]
 * @param {String} [sortDirection]
 *
 * @returns {Array}
 */

const filterCampaigns = (list, search, sortBy, sortDirection) => {
  return list
    .filter(c => {
      let name = c.name || ""
      return name
        .trim()
        .toLowerCase()
        .includes(search.trim().toLowerCase())
    })
    .sort((a, b) => {
      const a_date = dayjs(a.created_at).unix()
      const b_date = dayjs(b.created_at).unix()
      return sortDirection === "ASC" ? a_date - b_date : b_date - a_date
    })
}

export default filterCampaigns
