import React from "react"
import PropTypes from "prop-types"
import Screens from "./screens"
import Dates from "./dates"

const Cinema = ({ cinema }) => (
  <form className='p3 flex items-start'>
    <Screens cinema={cinema}/>
    <Dates cinema={cinema}/>
  </form>
)

Cinema.propTypes = {
  cinema: PropTypes.shape({
    screens: PropTypes.array.isRequired
  }).isRequired
}

export default Cinema
