import dayjs from "dayjs"

const getMaximumDay = (type, starts, ends) => {
  if (type === "starts_at" && ends) {
    return dayjs(ends)
      .subtract(1, "day")
      .toDate()
  } else return null
}

export default getMaximumDay
