import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import 'styled-components/macro'
import { logOut } from "@api/auth/index"
import { NavLink, withRouter } from "react-router-dom"
import { ButtonDropdown } from "@bitcine/cs-theme"
import AppSwitcher from "@bitcine/cinesend-app-switcher"
import { ReactComponent as Logo } from "@src/media/logos/logo.inline.svg"
import { GLOBALS } from "@src/config"

export const Navigation = ({ apps, logOut, name }) => (
  <nav
    css={`
      box-shadow: 0 1px 8px 0 rgba(146, 153, 159, 0.21);
    `}
    className='bg-white'>
    <div
      css={`
        height: 56px;
      `}
      className='flex items-center justify-between px3'>
      <div className='flex items-center'>
        <div
          css={`
            margin-left: -12px;
          `}
          className='mr2'>
          <AppSwitcher
            apps={Object.keys(apps)
              .filter(key => !apps[key].hide)
              .reduce((all, key) => ({ ...all, [key]: apps[key] }), {})}
            activeApplicationKey='bookings'/>
        </div>
        <NavLink to='/' className='flex items-center'>
          <Logo
            css={`
              width: 100px;
              height: 40px;
            `}/>
          <strong className='ml2'>Bookings</strong>
        </NavLink>
      </div>
      <ButtonDropdown
        button={{
          className: "link",
          text: (
            <div className='flex items-center'>
              {name}
              <span className='material-icons ml2'>keyboard_arrow_down</span>
            </div>
          )
        }}
        dropdown={{
          content: [
            {
              text: "My Settings",
              icon: "settings",
              onClick: () => window.open(GLOBALS.SETTINGS_URL, "_blank"),
              breakAfter: true
            },
            {
              text: "Log Out",
              icon: "exit_to_app",
              onClick: () => logOut()
            }
          ]
        }}/>
    </div>
    <div
      className='flex items-end px3'
      css={`
        height: 44px;
      `}>
      {[{ label: "Campaigns", to: "/campaigns" }, { label: "Consolidated Tracking", to: "/tracking" }].map(link => (
        <NavLink
          key={link.to}
          className='flex items-end px1 pb1'
          activeClassName='bold'
          data-test-id='navigation-tab-link'
          style={{
            borderBottom: "4px solid transparent"
          }}
          activeStyle={{
            color: "#FB0F3B",
            borderBottom: "4px solid #FB0F3B"
          }}
          to={link.to}>
          {link.label}
        </NavLink>
      ))}
    </div>
  </nav>
)

Navigation.defaultProps = {
  apps: {},
  name: ""
}

Navigation.propTypes = {
  apps: PropTypes.object,
  name: PropTypes.string,
  logOut: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  apps: state.auth.app_permissions,
  name: state.auth.full_name
})

const mapDispatchToProps = dispatch => ({
  logOut: bindActionCreators(logOut, dispatch),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Navigation)
)
