const CAMPAIGN_FORM_STEPS = [
  {
    step: "details",
    text: "Campaign Details",
    next_button_text: "Next",
    previous_button_text: "Cancel",
    title: "Campaign Details",
    description:
      "Name your campaign and pick a booking date range to pre-fill when you add venues. You can override booking windows for specific venues as you add them."
  },
  {
    step: "content",
    text: "Content",
    next_button_text: "Next",
    previous_button_text: "Back",
    title: "Campaign Content",
    description:
      "Select the DCPs (Digital Cinema Packages) from your CineSend account that will be delivered to each cinema in this campaign."
  },
  {
    step: "cinemas",
    text: "Cinemas",
    next_button_text: "Next",
    previous_button_text: "Back",
    title: "Campaign Cinemas",
    description:
      "Choose the Cinemas you wish to send your content to. Individual cinema screens can also be managed here."
  },
  {
    step: "review",
    text: "Review and Launch!",
    next_button_text: "Order Campaign",
    previous_button_text: "Back",
    title: "Review",
    description: ""
  }
]

export default CAMPAIGN_FORM_STEPS
