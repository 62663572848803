import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { updateCampaignFormStep, getCampaignFormAvailableContent } from "@api/campaign_form/"
import { connect } from "react-redux"
import { Modal, Status } from "@bitcine/cs-theme"
import { ReactComponent as Icon } from "@src/media/icons/media.inline.svg"
import ModalMaxHeightHelper from "@src/pages/campaigns/form/components/max_height_helper"
import Header from "./header"
import Project from "./project"
import Footer from "./footer"

class Select extends React.Component {
  componentDidMount = () => {
    this.props.getCampaignFormAvailableContent()
  }
  close = () => {
    this.props.updateCampaignFormStep("content", {
      available_list: {
        ...this.props.list,
        show: false
      }
    })
  }
  render() {
    const list = this.props.list
    const projects = list.projects.filter(project => {
      const title = project.title || ""
      return title
        .trim()
        .toLowerCase()
        .includes(list.search_term.trim().toLowerCase())
    })
    return (
      <Modal title='Add Content to Campaign' open={true} width={2} onClose={this.close}>
        <React.Fragment>
          <Header/>
          <Status
            error={list.status === "ERROR"}
            pending={list.status === "PENDING"}
            empty={!projects.length}
            emptyMessage={{
              icon: <Icon/>,
              title: list.search_term ? "No results found" : "No content found",
              text: list.search_term ? (
                "Try your search again"
              ) : (
                <span>
                  Try addding content to your{" "}
                  <a target='_blank' rel='noopener noreferrer' href='https://app.cinesend.com'>
                    CineSend account.
                  </a>
                </span>
              )
            }}>
            <ModalMaxHeightHelper>
              {projects.map(project => (
                <Project key={project._id} project={project}/>
              ))}
            </ModalMaxHeightHelper>
          </Status>
          <Footer/>
        </React.Fragment>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  list: state.campaign_form.steps.content.available_list
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch),
  getCampaignFormAvailableContent: bindActionCreators(getCampaignFormAvailableContent, dispatch)
})

Select.propTypes = {
  updateCampaignFormStep: PropTypes.func.isRequired,
  getCampaignFormAvailableContent: PropTypes.func.isRequired,
  list: PropTypes.shape({
    status: PropTypes.string.isRequired,
    projects: PropTypes.array.isRequired
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Select)
