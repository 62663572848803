import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Table, Button } from "@bitcine/cs-theme"
import { Collapse } from "react-collapse"
import "styled-components/macro"
import { removeCinemaFromNewCampaign, updateCampaignFormStep, resetNewCampaignFormStep } from "@api/campaign_form/index"
import { ReactComponent as EmptyIcon } from "@src/media/icons/empty.inline.svg"
import fadeIn from "@src/css/exports/fade_in"
import CinemaName from "@src/components/table/cinema_name"
import CinemaDates from "@src/components/table/cinema_dates"
import RowWrapper from "./components/row_wrapper"
import SaveNewTemplate from "./components/save_new_template"
import LoadSavedTemplates from "./components/load_saved_template/index"
import AddCinemasToCampaign from "./components/add_cinemas_to_campaign/index"
import Header from "./components/header"
import Cinema from "./components/cinema/index"
import CinemaDeliveryCost from "./components/table/cinema_delivery_cost"
import CinemaVendor from "./components/table/cinema_vendor"

class Cinemas extends React.Component {
  componentWillUnmount() {
    this.props.resetNewCampaignFormStep("cinemas")
  }
  toggleChecked = data => {
    this.props.updateCampaignFormStep("cinemas", {
      selected_cinema_ids: this.props.cinemas.selected_cinema_ids.includes(data._id)
        ? this.props.cinemas.selected_cinema_ids.filter(id => id !== data._id)
        : [...this.props.cinemas.selected_cinema_ids, data._id]
    })
  }
  toggleOpened = data => {
    this.props.updateCampaignFormStep("cinemas", {
      opened_cinema_ids: this.props.cinemas.opened_cinema_ids.includes(data._id)
        ? this.props.cinemas.opened_cinema_ids.filter(id => id !== data._id)
        : [...this.props.cinemas.opened_cinema_ids, data._id]
    })
  }
  toggleHeaderChecked = () => {
    this.props.updateCampaignFormStep("cinemas", {
      selected_cinema_ids: this.props.cinemas.selected_cinema_ids.length ? [] : this.filter().map(({ _id }) => _id)
    })
  }
  filter = () => {
    return this.props.model.cinemas.filter(cinema => {
      const name = cinema.name || ""
      return name
        .trim()
        .toLowerCase()
        .includes(this.props.cinemas.search_term.trim().toLowerCase())
    })
  }
  render() {
    const { cinemas } = this.props
    const list = this.filter()
    return (
      <div
        css={`
          ${fadeIn()}
        `}
        className='bg-white box-shadow rounded p3'>
        <Header/>
        <Table
          className='my3'
          widths={["auto", "150", "220", "220", "110", "110", "40", "100"]}
          header={{
            columns: [
              { text: "Cinema Name" },
              { text: "Delivery Cost" },
              { text: "Template" },
              { text: "Date" },
              { text: "Vendor" },
              { text: "Delivery" },
              { text: "" },
              { text: "" }
            ],
            checkbox: {
              checked: cinemas.selected_cinema_ids.length > 0,
              intermediate: cinemas.selected_cinema_ids.length !== list.length,
              onChange: value => this.toggleHeaderChecked(),
              disabled: !list.length
            }
          }}
          body={{
            data: list,
            empty: {
              icon: <EmptyIcon/>,
              title: cinemas.search_term ? "No content found" : "No cinemas selected",
              text: cinemas.search_term ? "Try your search again" : 'Click "Add cinemas" above to get started'
            },
            row: {
              onClick: (e, data) => this.toggleOpened(data),
              customRender: (children, data) => (
                <RowWrapper cinema={data}>
                  {children}
                  <Collapse isOpened={cinemas.opened_cinema_ids.includes(data._id)}>
                    <Cinema cinema={data}/>
                  </Collapse>
                </RowWrapper>
              ),
              checkbox: {
                checked: data => cinemas.selected_cinema_ids.includes(data._id),
                onChange: data => this.toggleChecked(data)
              },
              render: [
                data => <CinemaName cinema={data}/>,
                data => <CinemaDeliveryCost cinema={data}/>,
                data =>
                  this.props.model.template_cinema_map && this.props.model.template_cinema_map[data._id] ? (
                    this.props.model.template_cinema_map[data._id]
                  ) : (
                    <span className='muted'>N/A</span>
                  ),
                data => <CinemaDates cinema={data} model={this.props.model}/>,
                data => <CinemaVendor vendor={data.vendor}/>,
                data => (
                  <span className='capitalize'>
                    {data.delivery_method
                      ? data.delivery_method
                        .split("_")
                        .join(" ")
                        .toLowerCase()
                      : "N/A"}
                  </span>
                ),
                data => (
                  <span
                    css={`
                      transform: rotate(${cinemas.opened_cinema_ids.includes(data._id) ? "180deg" : "0deg"});
                      transition: all 0.2s linear;
                    `}
                    className='material-icons'>
                    keyboard_arrow_down
                  </span>
                ),
                data => (
                  <Button
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      this.props.removeCinemaFromNewCampaign(data._id)
                    }}
                    disabled={cinemas.removing_cinema_ids.includes(data._id)}
                    className='link underline'>
                    <span className='regular caption'>Remove</span>
                  </Button>
                )
              ]
            }
          }}/>
        <SaveNewTemplate/>
        <LoadSavedTemplates/>
        <AddCinemasToCampaign/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cinemas: state.campaign_form.steps.cinemas,
  model: state.campaign_form.model
})

const mapDispatchToProps = dispatch => ({
  removeCinemaFromNewCampaign: bindActionCreators(removeCinemaFromNewCampaign, dispatch),
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch),
  resetNewCampaignFormStep: bindActionCreators(resetNewCampaignFormStep, dispatch)
})

Cinemas.propTypes = {
  removeCinemaFromNewCampaign: PropTypes.func.isRequired,
  updateCampaignFormStep: PropTypes.func.isRequired,
  resetNewCampaignFormStep: PropTypes.func.isRequired,
  cinemas: PropTypes.shape({
    search_term: PropTypes.string.isRequired,
    removing_cinema_ids: PropTypes.array.isRequired,
    selected_cinema_ids: PropTypes.array.isRequired,
    opened_cinema_ids: PropTypes.array.isRequired
  }).isRequired,
  model: PropTypes.shape({
    cinemas: PropTypes.array.isRequired,
    template_cinema_map: PropTypes.object,
    cinema_overrider_dates_map: PropTypes.object,
    starts_at: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cinemas)
