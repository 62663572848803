import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "styled-components/macro"
import { updateCampaignFormStep, removeCinemasFromNewCampaign } from "@api/campaign_form/"
import { Tooltip, Button } from "@bitcine/cs-theme"

class Header extends React.Component {
  updateContent = payload => {
    this.props.updateCampaignFormStep("cinemas", payload)
  }
  openAddCinemasModal = () => {
    let payload = {
      available_list: {
        ...this.props.cinemas.available_list,
        show: true
      }
    }
    if (this.props.cinemas.show_add_cinemas_helper_tooltip) {
      payload.show_add_cinemas_helper_tooltip = false
    }
    this.updateContent(payload)
  }
  render() {
    const cinemas = this.props.cinemas
    return (
      <div className='flex tems-center justify-between'>
        <h4>Selected Cinemas</h4>
        <div className='flex items-center'>
          {cinemas.selected_cinema_ids.length ? (
            <Button onClick={this.props.removeCinemasFromNewCampaign} className='small outline'>
              Remove
            </Button>
          ) : (
            <React.Fragment>
              <input
                css={`
                  height: 34px;
                `}
                className='cs-input'
                value={cinemas.search_term}
                placeholder='Search cinemas...'
                onChange={e => this.updateContent({ search_term: e.target.value })}/>
              <Button
                onClick={() => this.updateContent({ new_template: { ...cinemas.new_template, show: true } })}
                className='small ml2 white'>
                Save as New Template
              </Button>
              <Button
                onClick={() => this.updateContent({ saved_templates: { ...cinemas.saved_templates, show: true } })}
                className='small ml2 white'>
                Load Template
              </Button>
              <Tooltip
                horizontal='right'
                render='Try adding cinemas to your campaign here!'
                hide={!cinemas.show_add_cinemas_helper_tooltip}>
                <Button className='small ml2' onClick={this.openAddCinemasModal}>
                  Add Cinemas
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cinemas: state.campaign_form.steps.cinemas
})

const mapDispatchToProps = dispatch => ({
  updateCampaignFormStep: bindActionCreators(updateCampaignFormStep, dispatch),
  removeCinemasFromNewCampaign: bindActionCreators(removeCinemasFromNewCampaign, dispatch)
})

Header.propTypes = {
  updateCampaignFormStep: PropTypes.func.isRequired,
  removeCinemasFromNewCampaign: PropTypes.func.isRequired,
  cinemas: PropTypes.shape({
    search_term: PropTypes.string.isRequired,
    available_list: PropTypes.object.isRequired,
    show_add_cinemas_helper_tooltip: PropTypes.bool.isRequired
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
